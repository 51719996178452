import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { selectUser } from '../feature/userSlice';
import { useSelector } from 'react-redux';
import { useHistory, Redirect, useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../assets/assets_BKLOGO.png';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Header from '../component/Header';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useDispatch } from 'react-redux';
import { login } from '../feature/userSlice';
import BarChartIcon from '@material-ui/icons/BarChart';
import TextField from '@material-ui/core/TextField';
import BeatLoader from "react-spinners/BeatLoader";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { mdiSyncCircle } from '@mdi/js';
import Icon from '@mdi/react';
import Moment from 'moment';
import { CSVLink, CSVDownload } from "react-csv";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'



const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		// display:"flex",
	},
	gridroot: {
		// height: '100vh',
		maxWidth: 'max-content',
		margin: 'auto',
		display: 'block',
		marginTop: '30px',
	},
	paper: {
		// margin: theme.spacing(8, 4),
		// display: 'flex',
		// flexDirection: 'column',
		// alignItems: 'center',
		width: '100%',
		marginTop: '20px',
	},
	container: {
		height: '100%',
	},

    tableHeaderFont : {color: 'white', fontWeight: 'bold', backgroundColor: "#3f51b5"},
    breadCrumb: {textDecoration: 'none', color: 'blue'}
}));

const ReportLine = ({state}) => {
	const classes = useStyles();
	const Details = state.Details.lineScreen;
	const [DetailsLocation, setDetailsLocation] = useState([]);
	const user = useSelector(selectUser);
	const userID = user ? user.userID : '';
	const companyID = user ? user.companyID : '';
	const companyIDVendor = user ? user.companyIDVendor : '';
	const factory = state.factory;
    const loading = state.loading;
	const location = useLocation();
	const [DateRange, setDatarange] = useState(state ? state.time : "");
	const selected = state.DateRange.lineScreen;
		var todayDate = new Date().toISOString().slice(0, 10);
		const [FromDate, setFromDate] = useState(todayDate);
		const [ToDate, setToDate] = useState(todayDate);
	const history = useHistory();
    const [headers, setHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);
	
	
	const [isShown, setIsShown] = useState(false);

	function handleChange(event) {
	//	setSelected(event.target.value);
		let tempDate =  state.DateRange;
        tempDate.lineScreen = event.target.value;
        state.setDateRange({...tempDate});
		// let _vals = event.target.value ? API_DATA.filter((r) => r.country === event.target.value) : API_DATA;
		// setTableData(_vals);
	}


	console.log(Details,"Data from App.js")

	const ApiUrl = "https://qualitylite.bluekaktus.com";

	// useEffect(() => {
	// 	function Auth() {
	// 		if (!user) {
	// 			history.push('/');
	// 		}
	// 	}
	// 	function GetDetails() {
	// 		// console.log(
	// 		// 	JSON.stringify({
	// 		// 		basicparams: {
	// 		// 			companyID: companyID,
	// 		// 			userID: userID,
	// 		// 		},
	// 		// 		reportParams: {
	// 		// 			fromDate: '',
	// 		// 			toDate: '',
	// 		// 			dateRange: DateRange,
	// 		// 			// locationID: this.state.factoryid,
	// 		// 			locationLevel: 'FACTORY',
	// 		// 		},
	// 		// 	})
	// 		// );
	// 		setLoading(true);
	// 	//	fetch(`${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport`, {
	// 		fetch(`${ApiUrl}/api/bkQuality/reports/getVendorLineOrderQtyReport`, {
	// 			method: 'POST',
	// 			body: JSON.stringify({
	// 				basicparams: {
	// 					companyID: companyIDVendor,
	// 					userID: userID,
	// 				},
	// 				reportParams: {
	// 					fromDate: '',
	// 					toDate: '',
	// 					dateRange: DateRange,
	// 					locationID: state.locationID,
	// 	//				locationLevel: 'LINE',
	// 				},
	// 			}),
	// 			headers: {
	// 				'Content-Type': 'application/json',
	// 				Accept: 'application/json',
	// 			},
	// 		})
	// 			.then((response) => response.json())
	// 			.then((responseJson) => {
	// 				// const data = responseJson;
	// 				const data = responseJson.result;
	// 				if (data.length > 0) {
	// 					const locationdata = data[0].locationDetails;
	// 					setDetailsLocation(locationdata);
	// 					// console.log(locationdata);
	// 				}
	// 				setDetails(data);
	// 				// console.log(data);
	// 				var fact = [];
	// 				var j;
	// 				for (j of responseJson.result) {
	// 					fact.push({ val: j.locationName, id: j.locationID });
	// 				}
	// 				setfactory(fact);
	// 				setLoading(false);
	// 				// console.log(data.result[0].locationDetails[0]);
	// 			});
	// 	}
	// 	function GetDetailsCustom() {
	// 		// console.log(
	// 		// 	JSON.stringify({
	// 		// 		basicparams: {
	// 		// 			companyID: companyID,
	// 		// 			userID: userID,
	// 		// 		},
	// 		// 		reportParams: {
	// 		// 			fromDate: FromDate,
	// 		// 			toDate: ToDate,
	// 		// 			dateRange: '',
	// 		// 			// locationID: this.state.factoryid,
	// 		// 			locationLevel: 'FACTORY',
	// 		// 		},
	// 		// 	})
	// 		// );
	// 		setLoading(true);
	// 		fetch(
    //         //    `${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport`,
	// 		`${ApiUrl}/api/bkQuality/reports/getVendorLineOrderQtyReport`,
    //             {
    //                 method: "POST",
    //                 body: JSON.stringify({
    //                     basicparams: {
    //                         companyID: companyIDVendor,
    //                         userID: userID,
    //                     },
    //                     reportParams: {
    //                         fromDate: FromDate,
    //                         toDate: ToDate,
    //                         dateRange: "",
    //                         locationID: state.locationID,
    //         //                locationLevel: "LINE",
    //                     },
    //                 }),
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Accept: "application/json",
    //                 },
    //             }
    //         )
    //             .then((response) => response.json())
    //             .then((responseJson) => {
    //                 // const data = responseJson;
    //                 const data = responseJson.result;
    //                 setDetails(data);
    //                 // console.log(data);
    //                 var fact = [];
    //                 var j;
    //                 for (j of responseJson.result) {
    //                     fact.push({ val: j.locationName, id: j.locationID });
    //                 }
    //                 setfactory(fact);
	// 				setLoading(false);
    //                 // console.log(data.result[0].locationDetails[0]);
    //             });
	// 	}
	// 	if(state && state.locationID)
	// 	{
	// 	Auth();
	// 	if (DateRange === 'custom' && FromDate !== '' && ToDate !== '') {
	// 		GetDetailsCustom();
	// 	} else {
	// 		GetDetails();
	// 	}
	// 	}
	// }, [DateRange, FromDate, ToDate]);

	//    const user = useSelector(selectUser);
	//    user?GetDetails():Auth();
	// console.log(`------------`);
	// console.log(Details.result[0]);

	
    useEffect(() => {
        window.onpopstate = ()=> {
             let temp = state.redirectData; 
             temp.path = "/report"; 
             state.setRedirectData({...temp}); }
      })

	  useEffect(() => {
		if(document.getElementById("toDate") || document.getElementById("fromDate"))
        {
        document.getElementById("toDate").value = Moment(state.ToDate.lineScreen).format('YYYY-MM-DD');
        document.getElementById("fromDate").value = Moment(state.FromDate.lineScreen).format('YYYY-MM-DD');
        }
        window.onbeforeunload = function() {
           return false;
        };    
        return () => { window.onbeforeunload = ''; };
    }, []);

	console.log(isShown)

	useEffect(() => {
        let temp = [
          { label: "Line", key: "LINE" },
          { label: "Style", key: "STYLE" },
          { label: "Buyer", key: "BUYER" },
          { label: "Order", key: "ORDER" },
          { label: "Order Qty", key: "ORDER_QTY" },
          { label: "Pending", key: "PENDING" },
          { label: "Pcs Stitched", key: "PCS_STITCHED" },
          { label: "Pcs Produced", key: "PCS_PRODUCED" },
          { label: "Pcs Checked", key: "PCS_CHECKED" },
          { label: "Ok Pcs", key: "OK_PCS" },
		  { label: "Rectified Pcs", key: "RECTIFIED_PCS" },
          { label: "Pcs in Alter", key: "PCS_IN_ALTER" },
          { label: "Rejected", key: "REJECTED" },
          { label: "DHU%", key: "DHU" },
        ];
        setHeaders(temp);
		console.log(Details)
        let tempCsv = [];
		var count=0
        for (let i = 0; i < Details.length; i++) {
		  if(Details.length>0){
		  for (let j = 0; j < Details[i].locationDetails.length; j++) {
			  console.log(count,i)
		 tempCsv[count] = {};
          tempCsv[count].LINE = Details[i].locationName;
          tempCsv[count].STYLE = Details[i].locationDetails[j].styleNo;
          tempCsv[count].BUYER = Details[i].locationDetails[j].brandName;
          tempCsv[count].ORDER = Details[i].locationDetails[j].orderNo;
          tempCsv[count].ORDER_QTY = Details[i].locationDetails[j].orderQty;
          tempCsv[count].PENDING = Details[i].locationDetails[j].pendingPieces;
          tempCsv[count].PCS_STITCHED = Details[i].locationDetails[j].stitchedPieces;
          tempCsv[count].PCS_PRODUCED = Details[i].locationDetails[j].producedPieces;
          tempCsv[count].PCS_CHECKED =
            parseInt(Details[i].locationDetails[j].okPieces) +
            parseInt(Details[i].locationDetails[j].alteredPieces) +
            parseInt(Details[i].locationDetails[j].pcsInAlteration) +
            parseInt(Details[i].locationDetails[j].rejectedPieces);
          tempCsv[count].OK_PCS = Details[i].locationDetails[j].okPieces;
		  tempCsv[i].RECTIFIED_PCS = Details[i].locationDetails[0].alteredPieces;
          tempCsv[count].PCS_IN_ALTER = Details[i].locationDetails[j].pcsInAlteration;
          tempCsv[count].REJECTED = Details[i].locationDetails[j].rejectedPieces;
          tempCsv[count].DHU = Details[i].locationDetails[j].dhu;
		  count++
		  }
		}
        }
		console.log(tempCsv)
        setCsvData(tempCsv);
      }, [Details]);

	function headerSyncFun(){
		state.getDetails(state.locationID, state.DateRange.lineScreen, state.FromDate.lineScreen, state.ToDate.lineScreen,"HeaderSync");
	}
    if(state && state.locationID)
	return (
		<>
			<div className={classes.root}>
				<Header title="REPORT LINE" redirectFunc={state.setRedirectData}  setLoginstatus={state.setLoginstatus} headerSyncFun={headerSyncFun} syncAllowed={true}/>

				{/* hello : <span> {user ? user.username : ''} </span>
				<br />
				UserId : <span> {user ? user.userID : ''} </span> */}

				<Grid container component="main" className={classes.gridroot}>
				<BeatLoader color={"#281d4d"} loading={loading} size={30} css={`               
    display: flex;
    justify-content: center;
    align-items: center;
                `}/>
					<CssBaseline />
					{/* <label value="Select Time: ">Select Time: </label> */}
					<Grid container justify="space-around">
					<div onClick={() => {let temp = state.redirectData; temp.path = state.parentPath; state.setRedirectData({...temp});<Redirect to="/report" />}}>
                    <AiOutlineArrowLeft size="40px" /> 
                    </div>
					<div style={{alignSelf: 'center'}}>
                	<span> <Link onClick={()=>{let temp = state.redirectData; temp.path = "/reportvendor"; state.setRedirectData({...temp});<Redirect to="/reportvendor" />}}  className={classes.breadCrumb}> {state.path[0]} </Link> / <Link onClick={()=>{let temp = state.redirectData; temp.path = "/report"; state.setRedirectData({...temp});<Redirect to="/report" />}} className={classes.breadCrumb}> {state.path[1]} </Link></span>
					{/* <Icon path={mdiSyncCircle}
                        title="Sync"
                        size={1.5}
                        horizontal
                        vertical
                        rotate={90}
                        style={{alignSelf: 'center'}}
                        onClick={() =>{state.getDetails(state.locationID, state.DateRange.lineScreen, state.FromDate.lineScreen, state.ToDate.lineScreen)}}
                        color="blue"
                    /> */}
                	</div>
						<Select
							style={{ width: '30%' }}
							value={selected}
							onChange={handleChange}
							name="country"
							displayEmpty
							autoWidth
							className={classes.selectEmpty}
						>
							<MenuItem value="today">Today</MenuItem>
							<MenuItem value="yesterday">Yesterday</MenuItem>
							<MenuItem value="lastSevenDays">Last 7 Days</MenuItem>
							<MenuItem value="lastThirtyDays">Last 30 Days</MenuItem>
							<MenuItem value="custom">Custom</MenuItem>
						</Select>
						
						<CSVLink
                                data={csvData}
                                headers={headers}
                                filename={"LineLevel-"+Moment(new Date()).format("DD-MM-YYYY") +".csv"}
                                className="btn btn-primary"
                                variant="contained"
                                component="label"
                                target="_blank"
                                style={{
                                    backgroundColor: "#3f51b5",
                                    padding:10,
                                    borderRadius: 5,
                                    color: "white",
                                    textDecoration: "none",
                                    alignSelf: 'center'
                                    }}
                            >
                                EXPORT TO EXCEL
                            </CSVLink>
						{state.DateRange.lineScreen === 'custom' ? (
							<TextField
								id="fromDate"
								label="Select Start Date"
								disabled={state.DateRange.lineScreen === 'custom' ? false : true}
								type="date"
								defaultValue={state.FromDate.lineScreen.slice(0, 10)}
								onChange={(e) => {
									var monthNames = [
										'Jan',
										'Feb',
										'Mar',
										'Apr',
										'May',
										'Jun',
										'Jul',
										'Aug',
										'Sep',
										'Oct',
										'Nov',
										'Dec',
									];
									var res = e.target.value.split('-');
									// console.log(res);
									var ans = res[2] + '-' + monthNames[parseInt(res[1]) - 1] + '-' + res[0];
									// console.log(ans);
									let tempFromDate = state.FromDate;
                                    tempFromDate.lineScreen = ans + " 00:00:00";
                                    state.setFromDate({...tempFromDate});
								}}
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						) : (
							''
						)}
						{state.DateRange.lineScreen === 'custom' ? (
							<TextField
								id="toDate"
								label="Select End Date"
								disabled={state.DateRange.lineScreen === 'custom' ? false : true}
								type="date"
								onChange={(e) => {
									var monthNames = [
										'Jan',
										'Feb',
										'Mar',
										'Apr',
										'May',
										'Jun',
										'Jul',
										'Aug',
										'Sep',
										'Oct',
										'Nov',
										'Dec',
									];
									var res = e.target.value.split('-');
									// console.log(res);
									var ans = res[2] + '-' + monthNames[parseInt(res[1]) - 1] + '-' + res[0];
									// console.log(ans);
									let tempToDate = state.ToDate;
                                    tempToDate.lineScreen = ans + " 23:59:59";
                                    state.setToDate({...tempToDate});
								}}
								defaultValue={state.ToDate.lineScreen.slice(0, 10)}
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						) : (
							''
						)}
                        {!loading ?
						<Link
						onClick={()=>{
							let tempData = state.redirectData;
							tempData.redirectStatus = true;
							tempData.path = "";
							state.setRedirectData({...tempData});
						}}
							to={{
								pathname: '/visualline',
								// search: '?query=abc',
								state: {
									Data: Details,
									locationID: state.locationID,
									factory: factory,
									time: state.DateRange.lineScreen,
									parentPath: "/reportline"
								},
							}}
						>
							<BarChartIcon style={{ fontSize: 40 }} />
						</Link>: null
						}
					</Grid>
					
					<Paper className={classes.paper}>
						<TableContainer component={Paper} style={{ margin: 'inherit', overflowX: "initial", width: "100vw" }}>
							<Table className={classes.table} aria-label="simple table" stickyHeader>
								<TableHead>

									{/* <TableRow> */}
									<TableRow>
										<TableCell  className={classes.tableHeaderFont} style={{ textAlign: 'left' }}>LINE</TableCell>
										<TableCell  className={classes.tableHeaderFont} style={{ textAlign: 'left' }}>Style</TableCell>
										<TableCell  className={classes.tableHeaderFont} style={{ textAlign: 'left' }}>Buyer</TableCell>
										<TableCell  className={classes.tableHeaderFont} style={{ textAlign: 'left' }}>Order</TableCell>
										<TableCell  className={classes.tableHeaderFont} style={{ textAlign: 'right' }}>Order Qty</TableCell>
										<TableCell  className={classes.tableHeaderFont} style={{ textAlign: 'right' }}>Pending</TableCell>
										<TableCell  className={classes.tableHeaderFont} style={{ textAlign: 'right' }}>Pcs Stitched</TableCell>
										<TableCell  className={classes.tableHeaderFont} style={{ textAlign: 'right' }}>Pcs Produced</TableCell>
										<TableCell  className={classes.tableHeaderFont} style={{ textAlign: 'right' }}>Pcs Checked</TableCell>
										<TableCell  className={classes.tableHeaderFont} style={{ textAlign: 'right' }}>OK Pcs</TableCell>
										<TableCell  className={classes.tableHeaderFont} style={{ textAlign: 'right' }}> Rectified Pcs</TableCell>
										<TableCell  className={classes.tableHeaderFont} style={{ textAlign: 'right' }}>Pcs in Alter</TableCell>
										<TableCell  className={classes.tableHeaderFont} style={{ textAlign: 'right' }}>Rejected</TableCell>
										<TableCell  className={classes.tableHeaderFont} style={{ textAlign: 'right' }}>DHU%</TableCell>
									</TableRow>
									{/* </TableRow> */}
								</TableHead>
								<TableBody>
											
									   {Details.map((Details, i) =>
									   
										Details.locationDetails.map((locationDetails, i) => (
											
											 
											
										<TableRow id="tablerow" 
										onClick={()=>{
											setIsShown(!setIsShown)
										}}
									
			                             style={{padding: 5,backgroundColor:locationDetails.finalInspectionStatus==1?"#49b675AA": locationDetails.finalInspectionStatus==2?"#c4342dAA": locationDetails.finalInspectionStatus==3?"#e1f987AA":"#FFFFFF"  }}>
										
										
									    <TableCell style={{ textAlign: 'left' }}>
													{Details.locationName}
												</TableCell>
												<TableCell style={{ textAlign: 'left' }}>
													<Link
													onClick={()=> {
														let temp = state.redirectData;
														temp.redirectStatus = true;
														temp.state.lineScreen = {
															locationID: Details.locationID,
															orderID: locationDetails.orderID,
															styleID: locationDetails.styleID,
															time: state.DateRange.lineScreen,
															path: state.path.concat([locationDetails.styleNo]),
															lineName: Details.locationName,
															styleNo: locationDetails.styleNo,
															parentPath: "/reportline"
														};
														temp.path = "/reportprocess";
														state.setRedirectData({...temp});
                                                      let tempDateRange = state.DateRange;
                                                    tempDateRange.processScreen = state.DateRange.lineScreen;
                                                    state.setDateRange({...tempDateRange});
													let tempFromDate = state.FromDate;
                                                    let tempToDate = state.ToDate;
                                                    tempFromDate.processScreen = state.FromDate.lineScreen;
                                                    tempToDate.processScreen = state.ToDate.lineScreen;
                                                    state.setFromDate({...tempFromDate});
                                                    state.setToDate({...tempToDate});
													if(state.DateRange.processScreen === state.DateRange.lineScreen && state.FromDate.processScreen === state.FromDate.lineScreen && state.ToDate.processScreen === state.ToDate.lineScreen)
                                                    {
                                                    state.nextScreenFunc(Details.locationID, locationDetails.orderID, locationDetails.styleID); //as we are updating DateRange, from and to date states we dont need to call this again.
													}
													<Redirect to="/reportprocess" />
														}}
														style={{ textDecoration: 'none', color: 'white'}}
														// to={{
														// 	pathname: '/reportprocess',
														// 	// search: '?query=abc',
														// 	state: {
														// 		locationID: Details.locationID,
														// 		orderID: locationDetails.orderID,
														// 		styleID: locationDetails.styleID,
														// 		time: DateRange,
														// 		path: state.path + "/" + locationDetails.styleNo,
														// 		lineName: Details.locationName,
														// 		styleNo: locationDetails.styleNo
														// 	},
														// }}
													>
														<div style={{backgroundColor: "#3f51b5", borderRadius: 5, padding: 10}}>
														{locationDetails.styleNo}
														</div>
													</Link>
												</TableCell>
												<TableCell style={{ textAlign: 'left' }}>
													{locationDetails.brandName}
												</TableCell>
												<TableCell style={{ textAlign: 'left' }}>
													{locationDetails.orderNo}
												</TableCell>
												<TableCell style={{ textAlign: 'right' }}>
													{locationDetails.orderQty}
												</TableCell>
												<TableCell style={{ textAlign: 'right' }}>
													{locationDetails.pendingPieces}
												</TableCell>
												<TableCell style={{ textAlign: 'right' }}>
													{locationDetails.stitchedPieces}
												</TableCell>
												<TableCell style={{ textAlign: 'right' }}>
													{locationDetails.producedPieces}
												</TableCell>
												<TableCell align="right">
                                                      {
                                                          parseInt(locationDetails.okPieces) +
														  parseInt(Details.locationDetails[0].alteredPieces) +
                                                          parseInt(locationDetails.pcsInAlteration) +
                                                          parseInt(locationDetails.rejectedPieces)
                                                      }
                                                  </TableCell>
												<TableCell style={{ textAlign: 'right' }}>
													{locationDetails.okPieces}
												</TableCell>
												<TableCell align="right">
                                                   {Details.locationDetails[0].alteredPieces}
                                                </TableCell>
												<TableCell style={{ textAlign: 'right' }}>
													{locationDetails.pcsInAlteration}
												</TableCell>
												<TableCell style={{ textAlign: 'right' }}>
													{locationDetails.rejectedPieces}
												</TableCell>
												<TableCell style={{ textAlign: 'right' }}>
													{locationDetails.dhu}
												</TableCell>
												{/* </TableRow> */}
											</TableRow>
										))
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</Grid>
				{isShown?<div
					onClick={()=>{
						setIsShown(!setIsShown)
					}}
				style={{position:"absolute",height:100, width:100, margin:300,top:300,left:300}}>
					Hello World
				</div>:null}
			</div>
		</>
	);
};

export default ReportLine;
