import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { selectUser } from '../feature/userSlice';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import {
	VictoryBar,
	VictoryChart,
	VictoryTheme,
	VictoryLabel,
	VictoryAxis,
	VictoryScatter,
	VictoryLine,
	VictoryVoronoiContainer,
    VictoryTooltip
} from 'victory';
import { useHistory, Redirect, useLocation } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../assets/assets_BKLOGO.png';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Header from '../component/Header';
import MenuItem from '@material-ui/core/MenuItem';
// import Option from '@material-ui/core/Option';
import Select from '@material-ui/core/Select';
import { useDispatch } from 'react-redux';
import { login } from '../feature/userSlice';
import { Line, defaults } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import bgimg from '../assets/linear-white-gradient-blue-1920x1080-c2-b0c4de-fff5ee-a-60-f-14.svg';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { ResponsiveLine } from '@nivo/line';

// defaults.global.tooltips.enabled = false;
// defaults.global.legend.position = 'bottom';
// import ReportLine from './ReportLine'
const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		// display: 'flex',
		height: '100vh',
		// backgroundImage: 'url(' + bgimg + ')',
		backgroundRepeat: 'no-repeat',
		// backgroundColor: '#281d4d',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		// flexDirection: 'column',
	},
	gridroot: {
		// height: '100vh',
		maxWidth: 'max-content',
		margin: 'auto',
		// display: 'contents',
		justifyContent: 'centre',
		alignItem: 'centre',
		alignContent: 'centre',
		marginTop: '20px',
	},
	loader: {
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
		margin: 'auto',
		width: '87px',
	},
	chart: {
		// width: '80%',
		margin: 'auto',
		marginTop: '20px',

		// display:"flex",
	},
	paper: {
		// margin: theme.spacing(8, 4),
		// display: 'flex',
		// flexDirection: 'column',
		// alignItems: 'center',
		width: '100vw',
		height: '68vh',
		display:"flex",
		// margin: 'inherit',
		backgroundColor: 'transparent',
	},
	header: {
		display: 'flex',
		alignContent: 'space-between',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		margin: 'inherit',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	// container: {
	// 	maxHeight: 440,
	// },
}));
const ApiUrl = "https://qualitylite.bluekaktus.com";
const VisualLine = ({state}) => {
	const classes = useStyles();
	const [Details, setDetails] = useState([]);
	const [DetailsLocation, setDetailsL] = useState([]);
	const [DateArray, setDateArray] = useState([]);
	const [chartData, setChartData] = useState([]);
	const [FromDate, setFromDate] = useState(new Date().toISOString().slice(0, 10) + " 00:00:00");
	const [ToDate, setToDate] = useState(new Date().toISOString().slice(0, 10) + " 23:59:59");
	// const [state, setState] = React.useState({
	// 	age: '',
	// 	name: '',
	// });
	const [DHU, setDHU] = useState([]);
	const [AlteredPieces, setAlteredPieces] = useState([]);
	const [item, setItem] = useState('producedPieces');
	const [dataChart, setDataChart] = useState({});
	const [labels, setLabels] = useState([]);
	const [Rejected, setRejected] = useState([]);
	const [col, setCol] = useState('#698bd0');
	const [StitchedPieces, setStitchedPieces] = useState([]);
	const [ProducedPieces, setProducedPieces] = useState([]);
	const [OkPieces, setOkPieces] = useState([]);
	const [PiecesInAlter, setPiecesInAlter] = useState([]);
	const user = useSelector(selectUser);
    const [loading, setLoading] = useState(false);
	const userID = user ? user.userID : '';
	const companyID = user ? user.companyID : '';
	const companyIDVendor = user ? user.companyIDVendor: "";
	const history = useHistory();
	const location = useLocation();
	var Factories = [];
	location.state.factory.forEach(function (item, index) {
		Factories.push({ label: item.val, value: item.id });
	});
	const [locationId, setLID] = useState(Factories[0] ? Factories[0].value : "");
	const [DateRange, setDatarange] = useState(location.state.time);
	const [selected, setSelected] = React.useState(location.state.time);
	const [selectFactory, setFactory] = useState(Factories[0] ? Factories[0].label : "");
	const [showData, setShowData] = useState('');
	const [selectFactoryID, setFactoryID] = useState(0);
	const [chartLine, setChartLine] = useState();
	const [graphData, setGraphData] = useState([]);
    const [loading1, setLoading1] = useState(false);
    const [dhuScatter, setDhuScatter] = useState("");

 	const handleCalendarClose = () => console.log('Calendar closed');
	const handleCalendarOpen = () => console.log('Calendar opened');
	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<button className="example-custom-input" onClick={onClick} ref={ref} color="blue">
			{value}
		</button>
	));
	function handleChange(event) {
		setSelected(event.target.value);
		setDatarange(event.target.value);
		setLoading(false);
		// let _vals = event.target.value ? API_DATA.filter((r) => r.country === event.target.value) : API_DATA;
		// setTableData(_vals);
	}
	function handleChangeItem(event) {
		setItem(event.target.value);
		setLoading(false);

		if (event.target.value === 'producedPieces') {
			setCol('#698bd0');
		}
		if (event.target.value === 'stitchedPieces') {
			setCol('#49b675');
		}
		if (event.target.value === 'pcsInAlteration') {
			setCol('#fce903');
		}
		if (event.target.value === 'alteredPieces') {
			setCol('#ff5733');
		}
		if (event.target.value === 'dhuValue') {
			setCol('#ffaa00');
		}
		if (event.target.value === 'rejectedPieces') {
			setCol('#e71837');
		}
		// let _vals = event.target.value ? API_DATA.filter((r) => r.country === event.target.value) : API_DATA;
		// setTableData(_vals);
	}
	function handleChangeFactory(val, val2) {
		setFactory(val.target.value);
		setLID(val2.props.value);
		setLoading(false);

		// console.log(val2.props);
	}
	function GetItemGraphLabels(data){
		var produced = [];
                        var stitched = [];
                        var alter = [];
                        var rej = [];
                        var dhu = [];
                        var producedlab = [];
                        var stitchedlab = [];
                        var alterlab = [];
                        var rejlab = [];
                        var dhulab = [];
						var dhuscatter = [];
                        var showdata = [];
                        var showlabel = [];
                        var linecolor = "#698bd0";
						var rectified = [];
						var rectifiedlab = [];
						var label = "";
                        data.forEach(function (item, index) {
							label = "";
                            if(item.prodTooltipDetails)
                            {
                                item.prodTooltipDetails.forEach(function (itemx, index) {
                                label = label + "  " + itemx.defectName +": " + itemx.frequency + "  ";
                                if(index != (item.prodTooltipDetails.length - 1))
                                {
                                    label = label + "\n";
                                }
                            })
                            }  
                            produced.push({
                                x: item.date,
                                y: item.producedPieces,
								l: label
                            });

							label = "";
                            if(item.stitchTooltipDetails)
                            {
                                item.stitchTooltipDetails.forEach(function (itemx, index) {
                                label = label + "  " + itemx.defectName +": " + itemx.frequency + "  ";
                                if(index != (item.stitchTooltipDetails.length - 1))
                                {
                                    label = label + "\n";
                                }
                            })
                            }
                            stitched.push({
                                x: item.date,
                                y: item.stitchedPieces,
								l: label
                            });

							label = "";
                            if(item.pcsInAlterTooltipDetails)
                            {
                                item.pcsInAlterTooltipDetails.forEach(function (itemx, index) {
                                label = label + "  " + itemx.defectName +": " + itemx.frequency + "  ";
                                if(index != (item.pcsInAlterTooltipDetails.length - 1))
                                {
                                    label = label + "\n";
                                }
                            })
                            }
                            alter.push({
                                x: item.date,
                                y: item.pcsInAlteration,
								l: label
                            });

							rectified.push({
                                x: item.date,
                                y: item.alteredPieces,
                            });

                            rej.push({ x: item.date, y: item.rejectedPieces });
							
							label = ""
							if(item.tooltipDetails)
							{
							item.tooltipDetails.forEach(function (itemx, index) {
								label = label + "  " + itemx.defectName +": " + itemx.frequency + "  ";
								if(index != (item.tooltipDetails.length - 1))
								{
									label = label + "\n";
								}
							})
							}
                            //dhu.push({ x: item.date, y: item.dhuValue });
							dhu.push({ x: item.date, y: item.dhuValue, l: label });
							dhuscatter.push({ x: item.date, y: item.dhuValue});
                            producedlab.push(item.producedPieces);
                            stitchedlab.push(item.stitchedPieces);
							rectifiedlab.push(item.rectified);
                            alterlab.push(item.pcsInAlteration);
                            rejlab.push(item.rejectedPieces);
                            dhulab.push(item.dhu);
                        });
                        if (item === "producedPieces") {
                            showdata = produced;
                            showlabel = producedlab;
                            linecolor = "#698bd0";
                        } else if (item === "stitchedPieces") {
                            showdata = stitched;
                            showlabel = stitchedlab;
                            linecolor = "#49b675";
                        } else if (item === "pcsInAlteration") {
                            showdata = alter;
                            showlabel = alterlab;
                            linecolor = "#fce903";
                        } else if (item === "rejectedPieces") {
                            showdata = rej;
                            showlabel = rejlab;
                            linecolor = "#e71837";
                        } else if (item === "alteredPieces") {
                            showdata = rectified;
                            showlabel = rectifiedlab;
                            linecolor = "#ff5733 ";
                        } else if (item === "dhuValue") {
                            showdata = dhu;
                            showlabel = dhulab;
                            linecolor = "#ffaa00";
                        }

                        // console.log(`----DATE---`);
                        setShowData(showdata);
                        setCol(linecolor);
                        setLabels(showlabel);
						setDetails(data);
                        setLoading(true);
						setDhuScatter(dhuscatter);
	}

	useEffect(() => {
		function Auth() {
			if (!user) {
				history.push('/');
			}
		}

		async function GetLocationDetails() {
			setLoading1(false);
					//	fetch(`${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport`, {
						fetch(`${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport_Processed`, {
							method: 'POST',
							body: JSON.stringify({
								basicparams: {
									companyID: companyID,
									userID: userID,
								},
								reportParams: {
									fromDate: '',
									toDate: '',
									dateRange: DateRange,
									locationID: location.state.locationID,
									locationLevel: 'LINE_FACTORY',
								},
							}),
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                }
            )
                .then((response) => response.json())
                .then((responseJson) => {
                    const data = responseJson.result;
                    setDetailsL(data);
					setLoading1(true);
                    return data;
                });
		}
		async function GetLocationDetailsCustom() {
			setLoading1(false);
					//	fetch(`${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport`, {
						fetch(
							`${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport_Processed`, 
							//`${ApiUrl}/api/bkQuality/reports/getVendorLineOrderQtyReport`,
							{
							method: 'POST',
							body: JSON.stringify({
								basicparams: {
									companyID: companyID,
									userID: userID,
								},
								reportParams: {
									fromDate: FromDate,
									toDate: ToDate,
									dateRange: "",
									locationID: location.state.locationID,
									locationLevel: 'LINE_FACTORY',
								},
							}),
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                }
            )
                .then((response) => response.json())
                .then((responseJson) => {
                    const data = responseJson.result;
                    setDetailsL(data);
					setLoading1(true);
                    return data;
                });
		}
		 function GetDetails() {
			setLoading(false);
			setDetails(
                 fetch(
					`${ApiUrl}/api/bkQuality/reports/getLocationLevelVisualReport_Processed`,
                //`http://9887-49-205-178-200.in.ngrok.io/api/bkQuality/reports/getLocationLevelVisualReport_Processed`,
					//    `${ApiUrl}/api/bkQuality/reports/getLocationLevelVisualReport`,
                    {
                        method: "POST",
                        body: JSON.stringify({
                            basicparams: {
                                companyID: companyID,
                                userID: userID,
                            },
                            reportParams: {
                                fromDate: "",
                                toDate: "",
                                dateRange: DateRange,
                                locationLevel: "LINE",
                                locationID: locationId,
                            },
                        }),
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    }
                )
                    .then((response) => response.json())
                    .then((responseJson) => {
                        const data = responseJson.result.length != 0
                            ? responseJson.result[0].locationDetails
                            : [];

							setGraphData(data);
							GetItemGraphLabels(data);
                        return data;
                    })
            );
		}
		 function GetDetailsCustom() {
			setLoading(false);
                 fetch(
					`${ApiUrl}/api/bkQuality/reports/getLocationLevelVisualReport_Processed`,
				//	`http://9887-49-205-178-200.in.ngrok.io/api/bkQuality/reports/getLocationLevelVisualReport_Processed`,
                //    `${ApiUrl}/api/bkQuality/reports/getLocationLevelVisualReport`,
                    {
                        method: "POST",
                        body: JSON.stringify({
                            basicparams: {
                                companyID: companyID,
                                userID: userID,
                            },
                            reportParams: {
                                fromDate: FromDate,
                                toDate: ToDate,
                                dateRange: "",
                                locationLevel: "LINE",
                                locationID: locationId,
                            },
                        }),
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    }
                )
                    .then((response) => response.json())
                    .then((responseJson) => {
                        // const data = responseJson;

                        const data = responseJson.result
                            ? responseJson.result[0].locationDetails
                            : [];
                        // setDetails(data);
                        setGraphData(data);
						GetItemGraphLabels(data);

                        return data;
                        //     console.log(DateArray);
                        //     console.log(DHU);
                    })
		}

		Auth();
		if (DateRange === 'custom' && FromDate !== '' && ToDate !== '') {
			GetLocationDetailsCustom();
			GetDetailsCustom();
		} else {
			GetLocationDetails();
			GetDetails();
		}
	}, [selectFactory, DateRange, FromDate, ToDate]);

	useEffect(() =>{
		if(graphData.length != 0)
        GetItemGraphLabels(graphData);
    }, [item]);
	//    const user = useSelector(selectUser);
	//    user?GetDetails():Auth();
	// console.log(Details);
	// var c = item;
	// const modifiedData = Details.map((val) => ({
	// 	date: val.date,
	//      data: val.c,
	// }));
	// setDataChart(modifiedData);
	// console.log(modifiedData);
	// const LineChart = Details.length ? (
	// 	<Line
	// 		data={{
	// 			label: modifiedData.map(({ date }) => date),
	// 			dataset: [
	// 				{
	// 					label: 'DHU%',
	// 					data: modifiedData.map(({ dhu }) => dhu),
	// 					borderColor: '#ffaa00',
	//                     fill:true,
	// 				},
	// 				// { label: 'Pcs Stitched', data: StitchedPieces, borderColor: '#49b675' },
	// 				// { label: 'Pcs Produced', data: ProducedPieces, borderColor: '#698bd0' },
	// 				// { label: 'Rejected Pieces', data: Rejected, borderColor: '#e71837' },
	// 				// { label: 'Pcs Altered', data: PiecesInAlter, borderColor: '#fce903' },
	// 			],
	// 		}}
	// 	/>
	// ) : null;
	// console.log(LineChart);
	// setChartLine(linechart);
	// console.log(showData);

	useEffect(() => {
        window.onpopstate = ()=> {
             let temp = state.redirectData; 
             temp.path = "/reportline"; 
             state.setRedirectData({...temp}); }
      })
	  useEffect(() => {
        window.onbeforeunload = function() {
           return false;
        };    
        return () => { window.onbeforeunload = ''; };
    }, []);
 
	return (
		<>
			<div className={classes.root}>
				<Header title="VISUAL REPORT LINE" redirectFunc={state.setRedirectData} setLoginstatus={state.setLoginstatus}/>

				{/* hello : <span> {user ? user.username : ''} </span>
				<br />
				UserId : <span> {user ? user.userID : ''} </span> */}

				<Grid container component="main" className={classes.gridroot}>
					{/* <CssBaseline /> */}
					{/* <div className={classes.header}> */}
					<Grid container justify="space-around">
					<div onClick={() => {
						let tempData = state.redirectData;
						tempData.redirectStatus = true;
						tempData.path = location.state.parentPath;
						state.setRedirectData({...tempData});}}>
                    <AiOutlineArrowLeft size="40px" /> 
                    </div>
						{/* <div> */}
						{/* <label value="Select Time: ">Select Time: </label> */}
						<Select
							style={{ margin: '0px 19px' }}
							value={selected}
							label="Select Time"
							onChange={handleChange}
							name="country"
							displayEmpty
							className={classes.selectEmpty}
						>
							<MenuItem value="today">Today</MenuItem>
							<MenuItem value="yesterday">Yesterday</MenuItem>
							<MenuItem value="lastSevenDays">Last 7 Days</MenuItem>
							<MenuItem value="lastThirtyDays">Last 30 Days</MenuItem>
							<MenuItem value="custom">Custom</MenuItem>
						</Select>
						{/* </div> */}
						{/* <label value="Select Time: ">Select Factory: </label> */}
						<FormControl className={classes.formControl}>
							{/* <InputLabel id="helper-label">Select Factory</InputLabel> */}
							<Select
								style={{ margin: '0px 19px' }}
								value={locationId}
								onChange={handleChangeFactory}
								label="Select Factory"
								labelId="helper-label"
								// id="demo-simple-select-autowidth"
								name="country"
								displayEmpty
								autoWidth
								className={classes.selectEmpty}
								// renderValue=(data) => <div>{data.join(", ")}</div>
							>
								{/* <MenuItem value="">
									<em>Select Factory:</em>
								</MenuItem> */}
								{DetailsLocation.map((details, i) => (
									<MenuItem value={(details.locationName, details.locationID)}>
										{details.locationName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						{/* <div> */}
						{/* <label value="Select Time: ">Select Item: </label> */}
						<Select
							style={{ margin: '0px 19px' }}
							value={item}
							onChange={handleChangeItem}
							name="country"
							displayEmpty
							className={classes.selectEmpty}
						>
							{/* <MenuItem value="">
								<em>Select Field:</em>
							</MenuItem> */}
							<MenuItem value="producedPieces">Pcs Produced</MenuItem>
							<MenuItem value="stitchedPieces">Pcs Stitched</MenuItem>
							<MenuItem value="pcsInAlteration">Pcs in Alter</MenuItem>
							<MenuItem value="alteredPieces">Pcs Rectified</MenuItem>
							<MenuItem value="dhuValue">DHU%</MenuItem>
							<MenuItem value="rejectedPieces">Pcs Rejected</MenuItem>
						</Select>
						{/* </div> */}
						{/* <label value="Select Start Date: ">Select Start Date : </label> */}
						{/* <label> */}
						{DateRange === 'custom' ? (
							<TextField
								id="date"
								label="Select Start Date"
								disabled={DateRange === 'custom' ? false : true}
								type="date"
								defaultValue={FromDate.slice(0, 10)}
								onChange={(e) => {
									var monthNames = [
										'Jan',
										'Feb',
										'Mar',
										'Apr',
										'May',
										'Jun',
										'Jul',
										'Aug',
										'Sep',
										'Oct',
										'Nov',
										'Dec',
									];
									var res = e.target.value.split('-');
									// console.log(res);
									var ans = res[2] + '-' + monthNames[parseInt(res[1]) - 1] + '-' + res[0];
									// console.log(ans);
									setFromDate(ans + ' 00:00:00');
									setLoading(false);

								}}
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						) : (
							''
						)}
						{DateRange === 'custom' ? (
							<TextField
								id="date"
								label="Select End Date"
								disabled={DateRange === 'custom' ? false : true}
								type="date"
								onChange={(e) => {
									var monthNames = [
										'Jan',
										'Feb',
										'Mar',
										'Apr',
										'May',
										'Jun',
										'Jul',
										'Aug',
										'Sep',
										'Oct',
										'Nov',
										'Dec',
									];
									var res = e.target.value.split('-');
									// console.log(res);
									var ans = res[2] + '-' + monthNames[parseInt(res[1]) - 1] + '-' + res[0];
									// console.log(ans);
									setToDate(ans + ' 23:59:59');
									setLoading(false);

								}}
								defaultValue={ToDate.slice(0, 10)}
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						) : (
							''
						)}
					</Grid>
					<div className={classes.chart}>
						<Paper className={classes.paper} elevation="0">
							{/* <Paper className={classes.paper}> */}
                            {loading && loading1 ? (
                                <ResponsiveLine
                                data={[{id: "value", color: "hsl(64, 70%, 50%)", data: showData}]}
                                curve='monotoneX'
                                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                                xScale={{ type: 'point' }}
                                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                yFormat=" >-.2f"
                                enablePointLabel={true}
                                axisTop={null}
                                axisRight={null}
                                axisLeft={null}
                                axisBottom={{
                                    orient: 'bottom',
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: -33,
                                    legendOffset: 36,
                                    legendPosition: 'middle'
                                }}

                                pointSize={8}
                                pointColor={[col]}
                                pointBorderWidth={2}
                                colors={[col]}
                                pointBorderColor={{ from: 'serieColor' }}
                                pointLabel={function(e){return e.y.toString()}}
                                enableCrosshair={false}
                                pointLabelYOffset={-12}
                                lineWidth={4}
                                tooltip={(input) => {
                                    return (
                                    <div style={{whiteSpace: 'pre', backgroundColor: 'white', border: `2px solid ${col}`, borderRadius: 5, padding: 5, backgroundColor: '#ffaa0088'}}>
                                      {input.point.data.l}
                                    </div>
                                  )}}
                                enableGridX={false}
                                enableGridY={false}
                                enableArea={true}
                                areaOpacity={0.2}
                                useMesh={true}
                                animate={true}
                                borderColor="#ffffffff"
                            />
								// <VictoryChart
								// 	animate={{ duration: 500 }}
								// 	minDomain={{ y: 0 }}
								// 	height={window.innerHeight * 0.6}
								// 	width={window.innerWidth}
								// 	domainPadding={{ x: 20, y: 0 }}
								// 	marginBottom={10}
								// 	containerComponent={
                                //         <VictoryVoronoiContainer 
                                //         mouseFollowTooltips
                                //         voronoiDimension="x"
                                //           labels={({ datum }) => datum.l && `${datum.l}`}
                                //           labelComponent={<VictoryTooltip cornerRadius={0} flyoutStyle={{fill: "white"}}/>}
                                //         />
                                //       }
								// >
								// 	<VictoryLine
								// 		style={{
								// 			padding: 10,
								// 			data: { stroke: col, strokeWidth: 5 },

								// 			parent: { border: '1px solid #ccc' },
								// 		}}
								// 		//   interpolation="natural"
								// 		data={showData}
								// 		alignment="middle"
								// 		cornerRadius={4}
								// 	/>
								// 	<VictoryScatter
                                //         data={item === "dhuValue" ? dhuScatter : showData}
								// 		size={5}
								// 		style={{ data: { fill: '#698bd0' } }}
								// 		labels={labels}
								// 		labelComponent={
								// 			<VictoryLabel
								// 				animate={{
								// 					duration: 2000,
								// 					onLoad: { duration: 1000 },
								// 				}}
								// 				style={{ fill: '#698bd0', fontSize: 13 }}
								// 			/>
								// 		}
								// 	/>
								// 	<VictoryAxis
								// 		fixLabelOverlap={true}
								// 		style={{
								// 			axis: { stroke: '#3c67bf', strokeWidth: 2 },
								// 			axisLabel: {
								// 				fontSize: 14,
								// 				padding: 30,
								// 				fontWeight: 'bold',
								// 				fill: '#3c67bf',
								// 			},
								// 			label: { fill: '#3c67bf' },

								// 			ticks: { stroke: '#3c67bf', size: 5 },
								// 			tickLabels: {
								// 				fill: '#3c67bf',
								// 				fontSize: 10,
								// 				padding: 1,
								// 				fontWeight: 'bold',
								// 			},
								// 		}}
								// 	/>
								// </VictoryChart>
							) : (
								<CircularProgress className={classes.loader} size={80} />
							)}
						</Paper>
					</div>
				</Grid>
			</div>
		</>
	);
};

export default VisualLine;
