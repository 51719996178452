// import './App.css';
import React , {useState, useEffect } from 'react';
import axios from 'axios';
import SignIn from './screens/SignIn.js';
import SignUp from './screens/SignUp';
import Report from './screens/Report';
import {BrowserRouter as Router,Route,Link,Switch} from 'react-router-dom';
import { selectUser, dateState } from './feature/userSlice';
import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import ReportFloor from './screens/ReportFloor';
import ReportLine from './screens/ReportLine'
import ReportProcess from './screens/ReportProcess'
import VisualReport from './screens/VisualReport'
import VisualFloor from './screens/VisualFloor'
import VisualLine from './screens/VisualLine'
import VisualProcess from './screens/VisualProcess'
import ReportDefect from './screens/ReportDefect'
import OrderExcelFile from './screens/OrderExcelFile'
import ReportVendor from './screens/ReportVendor.js';
import VendorVisualReport from './screens/VendorVisualReports.js';
import ReportStyle from './screens/ReportStyle.js';
import { Helmet } from "react-helmet";

import { useDispatch } from "react-redux";
import { login } from "./feature/userSlice";
import { useCookies } from 'react-cookie';

import * as Sentry from "@sentry/react";

function App() {

	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const intialDateState = useSelector(dateState);
	const [DateRange, setDateRange] = useState({
		vendorScreen: "today",
	})
	const [FromDate, setFromDate] = useState({
		vendorScreen: intialDateState.fromDate + " 00:00:00",
		reportScreen: new Date().toISOString().slice(0, 10) + " 00:00:00",
		lineScreen: new Date().toISOString().slice(0, 10) + " 00:00:00",
		processScreen:  new Date().toISOString().slice(0, 10) + " 00:00:00"
	})
	const [ToDate, setToDate] = useState({
		vendorScreen: intialDateState.toDate  + " 23:59:59",
		reportScreen: new Date().toISOString().slice(0, 10) + " 23:59:59",
		lineScreen: new Date().toISOString().slice(0, 10) + " 23:59:59",
		processScreen:  new Date().toISOString().slice(0, 10) + " 23:59:59"
	})
	const [Details, setDetails] = useState({
		vendorScreen: [],
		reportScreen: [],
		lineScreen: []
	});

	const [factory, setfactory] = useState({vendorScreen: [], reportScreen: [], lineScreen: []});
	const userID = user ? user.userID : "";
    const companyIDVendor = user ? user.companyIDVendor : "";
	const companyID = user ? user.companyID : "";

    const [loginStatus, setLoginstatus] = useState(-1);
	const [redirectData, setRedirectData] = useState({redirectStatus: false, state: {}, path: "/"})
	const [loading, setLoading] = useState({
		vendorScreen: true,
		reportScreen: true,
		lineScreen: true,
		processScreen: true
	});
	const [processLineCheckbox, setProcessLineCheckbox] = useState(false);
	const [cookies, setCookie, removeCookie] = useCookies(['user']);
	const ApiUrl = "https://qualitylite.bluekaktus.com";

	const LogIn = (username, password) => {
			var details = {
				username: username,
				password: password,
				appID: "MASTERS",
				grant_type: "password",
			};
			// console.log(details)
			var formBody = [];
			for (var property in details) {
				var encodedKey = encodeURIComponent(property);
				var encodedValue = encodeURIComponent(details[property]);
				formBody.push(encodedKey + "=" + encodedValue);
			}
			formBody = formBody.join("&");
			console.log(formBody);
			let headers = new Headers();
			headers.append("Content-Type", "application/x-www-form-urlencoded");
			headers.append("Access-Control-Allow-Origin", "*");
			headers.append("Accept", "application/json");
			// headers.append('Origin', 'http://localhost:3000');
			fetch(`${ApiUrl}/Token`, {
				method: "POST",
				headers: headers,
				body: formBody,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === null || responseJson.error === "") {
						console.log(`Access Granted`);
						Sentry.captureException("Login Sucessful");
						const data = responseJson;
						
					//	setCookie('user', {name : username, password: password, data: data}, { path: '/' });
						setLoginFunction(username, password, data);
					// history.push("reportvendor");
					} else {
						console.log(`Access Denied`);
						setLoginstatus(0);
						<h2>Invalid Username or Password</h2>;
					}
				}).catch((error) => {console.log("ERROR: ", error)})
	}
	
	const setLoginFunction = (username, password, data) =>{
		dispatch(
			login({
				username: username,
				password: password,
				userID: data.userID,
				companyID: data.companyID,
				companyIDVendor: data.companyID,
				data: data,
				loggedIn: true,
			})
		);
		setRedirectData({redirectStatus: true, state: {}, path: "/reportvendor"});
		VendorDetails(data.companyID, data.userID);
		setLoginstatus(1);
	}
	const VendorDetails = (vendorID = "", initUserID = "") =>{
		let tempLoading = loading;
		tempLoading.vendorScreen = true;
		setLoading({...tempLoading});
		//setLoading(true);
		let emptyData = Details;
		emptyData.vendorScreen = [];
		setDetails({...emptyData});					
		emptyData = factory;
		emptyData.vendorScreen = [];
		setfactory({...emptyData});
		let reportParams = {}
		if(DateRange.vendorScreen === "custom" && FromDate.vendorScreen !== "" && ToDate.vendorScreen !== ""){
			reportParams = {
							fromDate: FromDate.vendorScreen,
                            toDate: ToDate.vendorScreen,
                            dateRange: "",
							"locationLevel": "VENDOR"
							}
		}
		else{
			reportParams = {
							fromDate: "",
                            toDate: "",
                            dateRange: DateRange.vendorScreen,
							"locationLevel": "VENDOR"
				}
		}
            fetch(
               // `${ApiUrl}/api/bkQuality/reports/getVendorOrderQtyReport`,
              // "https://ee68-103-95-82-250.in.ngrok.io/api/bkQuality/reports/getVendorOrderQtyReport_V2",
			 // "http://90e1-103-95-82-83.in.ngrok.io/api/bkQuality/reports/getLocationLevelOrderQtyReport_Processed",
               `${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport_Processed`,
                {
					
                    method: "POST",
                    body: JSON.stringify({
                        basicparams: {
                            companyID: companyIDVendor === "" ? vendorID : companyIDVendor,
                            userID: userID === "" ? initUserID : userID,
                        },
                        reportParams: reportParams,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                }
            )
                .then((response) => response.json())
                .then((responseJson) => {
                    const data = responseJson.result;
					let tempData = Details;
					tempData.vendorScreen = data;
                    setDetails(tempData);
                    var fact = [];
                    var j;
                    for (j of responseJson.result) {
                        fact.push({ val: j.companyName, id: j.companyID });
                    }
					let temp = factory;
					temp.vendorScreen = fact;
                    setfactory({...temp});
					let tempLoading = loading;
					tempLoading.vendorScreen= false;
					setLoading({...tempLoading});
					//setLoading(false);
					return true;
                    // console.log(data.result[0].locationDetails[0]);
                });
	}
	const ReportDetails = (companyid = companyID, dateRange = DateRange.vendorScreen, fromDate = FromDate.vendorScreen, toDate = ToDate.vendorScreen) =>{
		let tempLoading = loading;
		tempLoading.reportScreen = true;
		setLoading({...tempLoading});

		//setLoading(true);
		let emptyData = Details;
		emptyData.reportScreen = [];
		setDetails({...emptyData});	
		console.log(emptyData,"beforeemptydata")

		emptyData = factory;
		emptyData.reportScreen = [];
		setfactory({...emptyData});
		
		let reportParams = {}
		if(dateRange === "custom" && fromDate !== "" && toDate !== ""){
			reportParams = {
							fromDate: fromDate,
                            toDate: toDate,
                            dateRange: "",
							locationLevel: "FACTORY",
							}
		}
		else{
			reportParams = {
							fromDate: "",
                            toDate: "",
                            dateRange: dateRange,
							locationLevel: "FACTORY",
				}
		}
		fetch(
			`${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport_Processed`,
			{
				method: "POST",
				body: JSON.stringify({
					basicparams: {
						companyID: companyid,
						userID: userID,
					},
					reportParams: reportParams,
				}),
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			}
		)
			.then((response) => response.json())
			.then((responseJson) => {
				// const data = responseJson;
				const data = responseJson.result;
				let tempData = Details;
				tempData.reportScreen = data;
				setDetails({...tempData});
				var fact = [];
				var j;
				for (j of responseJson.result) {
					fact.push({ val: j.locationName, id: j.locationID });
				}
				let temp = factory;
				temp.reportScreen = fact;
				setfactory({...temp});
				let tempLoading = loading;
				tempLoading.reportScreen= false;
				setLoading({...tempLoading});
				//setLoading(false);
				// console.log(data.result[0].locationDetails[0]);
			});
	}

	const lineDetails = (locationID, dateRange = DateRange.reportScreen, fromDate = FromDate.reportScreen, toDate = ToDate.reportScreen,source) => {
		let tempLoading = loading;
		console.log(source)
		tempLoading.lineScreen = true;
		setLoading({...tempLoading});
		//setLoading(true);
		let emptyData = Details;
		emptyData.lineScreen = [];
		setDetails({...emptyData});					
		emptyData = factory;
		emptyData.lineScreen = [];
		setfactory({...emptyData});
		let reportParams = {}
		if(dateRange === "custom" && fromDate !== "" && toDate !== ""){
			reportParams = {
				fromDate: fromDate,
				toDate: toDate,
				dateRange: "",
				locationID: locationID,
                locationLevel: "LINE_FACTORY",
			}
		}
		else{
			reportParams = {
				fromDate: "",
				toDate: "",
				dateRange: dateRange,
				locationID: locationID,
                locationLevel: "LINE_FACTORY",
			}
		}
		fetch(
		//    `${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport`,
		`${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport_Processed`,
			{
				method: "POST",
				body: JSON.stringify({
					basicparams: {
						companyID: companyID,
						userID: userID,
					},
					reportParams: reportParams,
				}),
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			}
		)
			.then((response) => response.json())
			.then((responseJson) => {
				// const data = responseJson;
				const data = responseJson.result;
				let tempData = Details;
				tempData.lineScreen = data;
				setDetails({...tempData});
				//console.log(data,"process details");
				var fact = [];
				var j;
				for (j of responseJson.result) {
					fact.push({ val: j.locationName, id: j.locationID });
				}
				let temp = factory;
				temp.lineScreen = fact;

				setfactory({...temp});

				let tempLoading = loading;
				tempLoading.lineScreen = false;
				setLoading({...tempLoading});
				//setLoading(false);
				// console.log(data.result[0].locationDetails[0]);
			});
	}

	const ProcessDetails = (locationID, orderID, styleID, dateRange = DateRange.lineScreen, fromDate = FromDate.lineScreen, toDate = ToDate.lineScreen) => {
		let tempLoading = loading;
		tempLoading.processScreen = true;
		setLoading({...tempLoading});
		//setLoading(true);

		let emptyData = Details;
		emptyData.processScreen = [];
		setDetails({...emptyData});					
		emptyData = factory;
		emptyData.processScreen = [];
		setfactory({...emptyData});

		let reportParams = {}
		if(dateRange === "custom" && fromDate !== "" && toDate !== ""){
			reportParams = {
				fromDate: fromDate,
				toDate: toDate,
				dateRange: "",
				orderID: orderID,
				styleID: styleID,
				locationLevel: "PROCESS",
			}
		}
		else{
			reportParams ={
				fromDate: "",
				toDate: "",
				dateRange: dateRange,
				orderID: orderID,
				styleID: styleID,
				locationLevel: "PROCESS",
			}
		}
		if(!processLineCheckbox)
		{
			reportParams.locationID = locationID
		}
		fetch(
			`${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport_Processed`,
			{
				method: "POST",
				body: JSON.stringify({
					basicparams: {
						companyID: companyID,
						userID: userID,
					},
					reportParams: reportParams,
				}),
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			}
		)
			.then((response) => response.json())
			.then((responseJson) => {
				// const data = responseJson;
				// console.log(data);
				const data = responseJson.result;
				if (data.length > 0) {
					// console.log(locationdata);
				let tempData = Details;
				tempData.processScreen = data;
				setDetails({...tempData});
					var fact = [];
					var j;
					for (j of responseJson.result) {
						var i;
						for (i of j.locationDetails) {
							// floor.push({val:j.locationName,id:j.locationID})
							fact.push({
								val: i.processName,
								id: i.processID,
							});
						}
					}
					let temp = factory;
					temp.processScreen = fact;
					setfactory({...temp});
					
				console.log(data);
				}
				let tempLoading = loading;
				tempLoading.processScreen = false;
				setLoading({...tempLoading});
				//setLoading(false);
				// console.log(data.result[0].locationDetails[0]);
			});
	}

	useEffect(() =>{
		removeCookie("user");
	},[])

	    useEffect(() => {
			if(user)
			{
		VendorDetails();
			}
    }, [DateRange.vendorScreen, FromDate.vendorScreen, ToDate.vendorScreen]);

	useEffect(() => {
		if(user && redirectData.path === "/report")
		{
			ReportDetails(companyID, DateRange.reportScreen,FromDate.reportScreen,ToDate.reportScreen);
		}
	}, [DateRange.reportScreen, FromDate.reportScreen, ToDate.reportScreen]);

	useEffect(() => {
		if(user && redirectData.path === "/reportline")
		{
			lineDetails(redirectData.state.reportScreen.locationID, DateRange.lineScreen,FromDate.lineScreen,ToDate.lineScreen,"App.js Use Effect");
		}
	}, [DateRange.lineScreen, ToDate.lineScreen]);
//   console.log(Details,"reportscreen")
	useEffect(() => {
		if(user && redirectData.path === "/reportprocess")
		{
			ProcessDetails(redirectData.state.lineScreen.locationID,redirectData.state.lineScreen.orderID, redirectData.state.lineScreen.styleID, DateRange.processScreen,FromDate.processScreen,ToDate.processScreen);
		}
	}, [DateRange.processScreen, FromDate.processScreen, ToDate.processScreen, processLineCheckbox]);

  return (
	<React.Fragment>
	<Helmet>
	  <title>ZedQ Reports</title>
	</Helmet>
		<Router>
			<div className="App">
				{/* {user ? <Report /> : <SignIn />} */}
				{/* <Switch> */}
					{/* <Route exact path="/" component={SignIn} /> */}
					{redirectData.path === "/" ?
					<SignIn 
					state={{				
						loginStatus: loginStatus,
						LogIn: LogIn,
						setRedirectData: setRedirectData,
						redirectData: redirectData,
						setLoginFunction: setLoginFunction
					}}
				/> : null}
				<Route path="/reportvendor" component={ReportVendor} />
				{redirectData.path === "/reportvendor" ?
				<ReportVendor
				state={{
					getDetails: VendorDetails,
					DateRange: DateRange,
					Details: Details,
					setDateRange: setDateRange,
					factory: factory.vendorScreen,
					ToDate: ToDate,
					FromDate: FromDate,
					setToDate: setToDate,
					setFromDate: setFromDate,
					setRedirectData: setRedirectData,
					nextScreenFunc: ReportDetails,
					redirectData: redirectData,
					loading: loading.vendorScreen,
					setLoginstatus: setLoginstatus
				}}
				/> 
				: null}
				
				{/* <VendorVisualReport
				state={redirectData.state} 
				/> */}
					<Route path="/report" component={Report} />
					{redirectData.path === "/report" ? 
					
					<Report state={{...redirectData.state.vendorScreen,
					getDetails: ReportDetails,
					DateRange: DateRange,
					Details: Details,
					setDateRange: setDateRange,
					factory: factory.reportScreen,
					ToDate: ToDate,
					FromDate: FromDate,
					setToDate: setToDate,
					setFromDate: setFromDate,
					loading: loading.reportScreen,
					nextScreenFunc: lineDetails,
					setRedirectData: setRedirectData, 
					setLoginstatus: setLoginstatus,
					redirectData: redirectData}}  />
					: null}

					<Route path="/reportline" component={ReportLine} />
					{redirectData.path === "/reportline"  ? 
					<ReportLine state={{...redirectData.state.reportScreen,
						getDetails: lineDetails,
						DateRange: DateRange,
						Details: Details,
						setDateRange: setDateRange,
						factory: factory.lineScreen,
						ToDate: ToDate,
						FromDate: FromDate,
						setToDate: setToDate,
						setFromDate: setFromDate,
						loading: loading.lineScreen,
						nextScreenFunc: ProcessDetails,
						setRedirectData: setRedirectData, 
						setLoginstatus: setLoginstatus,
						redirectData: redirectData}}  />
					: null}

					<Route path="/signup" component={SignUp} />
					<Route path="/reportfloor" component={() => <ReportFloor />} />
					{ redirectData.path === "" ? 
					<Route path="/vendorvisualreport" component={() => <VendorVisualReport state={{redirectData: redirectData, setRedirectData: setRedirectData, setLoginstatus: setLoginstatus}}/>} />
					: null }
					{ redirectData.path === "" ? 
					<Route path="/visualreport" component={ () => <VisualReport state={{redirectData: redirectData, setRedirectData: setRedirectData, setLoginstatus: setLoginstatus}}/>} />
					: null }
					{ redirectData.path === "" ? 
					<Route path="/visualline" component={() => <VisualLine state={{redirectData: redirectData, setRedirectData: setRedirectData, setLoginstatus: setLoginstatus}}/>} />
					: null }
					{ redirectData.path === "" ? 
					<Route path="/visualprocess" component={ () => <VisualProcess state={{redirectData: redirectData, setRedirectData: setRedirectData, setLoginstatus: setLoginstatus}}/>} />
					: null }
					 
					<Route path="/reportprocess" component={() => <ReportProcess state={{redirectData: redirectData, setRedirectData: setRedirectData, setLoginstatus: setLoginstatus}}/>} />
					{redirectData.path === "/reportprocess"  ? 
					<ReportProcess state={{...redirectData.state.lineScreen,
						getDetails: ProcessDetails,
						DateRange: DateRange,
						Details: Details,
						setDateRange: setDateRange,
						factory: factory.processScreen,
						ToDate: ToDate,
						FromDate: FromDate,
						setToDate: setToDate,
						setFromDate: setFromDate,
						loading: loading.processScreen,
						setCheckbox: setProcessLineCheckbox,
						checkbox: processLineCheckbox,
						setRedirectData: setRedirectData,
						setLoginstatus: setLoginstatus,
						redirectData: redirectData}}  />
					: null}
					{ redirectData.path === "" ?
					<Route path="/reportdefect" component={() => <ReportDefect state={{redirectData: redirectData, setRedirectData: setRedirectData, setLoginstatus: setLoginstatus}}/>} />
					: null }
					<Route path="/visualfloor" component={VisualFloor} />
					
					
					<Route path="/orderexcelfile" component={OrderExcelFile} />
					{ redirectData.path === "" ?
					<Route path="/reportstyle" component={() => <ReportStyle state={{redirectData: redirectData, setRedirectData: setRedirectData, setLoginstatus: setLoginstatus}}/>} />
					: null }
					{/* <SignIn /> */}
				{/* </Switch> */}
			</div>
		</Router>
		</React.Fragment>
  );
};

export default App;
