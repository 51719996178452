import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import logo from '../assets/assets_BKLOGO.png';
import { useDispatch } from 'react-redux';
import { logout } from '../feature/userSlice';
import { useHistory,Link, Redirect} from 'react-router-dom';
import { mdiSync } from '@mdi/js';
import Icon from '@mdi/react';
import arvindLogo from '../assets/arvindLogo.png';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	logo: {
		maxWidth: 80,
	},
	title: {
		flexGrow: 1,
	},
}));


function Header(props) {

	const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const Submit = () => {
		props.setLoginstatus(-1);
		props.redirectFunc({redirectStatus: true, state: {}, path: "/"});
		dispatch(logout());
		//  <Redirect to="/" />
        // history.push('/');

	};

	return (
		<>
			<div className={classes.root}>
				<AppBar position="static">
					<Toolbar>
						<Toolbar>
							<img src={logo} alt="logo" className={classes.logo} />
						</Toolbar>
						<Typography variant="h6" className={classes.title}>
							{props.title}
						</Typography>
						{/* <Toolbar>
						<img src={arvindLogo} alt="arvind"  style={{maxWidth: 80,}} />
						</Toolbar> */}
						{/* <Link to='/orderexcelfile' style={{ textDecoration: 'none' }}>
						<span style={{color: "#fffffffa"}}> Upload File </span>
						</Link> */}
						{props.syncAllowed ?
						<Icon path={mdiSync}
                        title="Sync"
                        size={1.5}
                        horizontal
                        vertical
                        rotate={90}
                        style={{alignSelf: 'center', marginRight: 20}}
                        onClick={() =>{props.headerSyncFun()}}
                        color="white"
                    /> : null
						}
						<Button
							color="inherit"
							onClick={() => {
								Submit();
							}}
						>
							Logout
						</Button>
					</Toolbar>
				</AppBar>
			</div>
		</>
	);
}

export default Header;
