import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import {
  selectUser,
  dateState,
  setIntialDateState,
} from "../feature/userSlice";
import { useSelector } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../assets/assets_BKLOGO.png";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Header from "../component/Header";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useDispatch } from "react-redux";
import BarChartIcon from "@material-ui/icons/BarChart";
import TextField from "@material-ui/core/TextField";
import { setCompany } from "../feature/userSlice";
import FadeLoader from "react-spinners/FadeLoader";
import BeatLoader from "react-spinners/BeatLoader";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { mdiSyncCircle } from "@mdi/js";
import { useCookies } from "react-cookie";
import Icon from "@mdi/react";
import Moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";

// import ReportLine from './ReportLine'
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // display:"flex",
  },
  gridroot: {
    // height: '100vh',
    maxWidth: "max-content",
    margin: "auto",
    display: "block",
    marginTop: "30px",
  },
  paper: {
    width: "100%",
    marginTop: "20px",
  },
  header: {
    display: "flex",
    alignContent: "space-between",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  container: {
    maxHeight: 440,
  },
  tableHeaderFont: {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "#3f51b5",
    whiteSpace: "pre",
  },
}));

const ReportVendor = ({ state }) => {
  const classes = useStyles();
  const intialDateState = useSelector(dateState);
  const DateRange = state ? state.DateRange : "today";
  const selected = state ? state.DateRange.vendorScreen : "today";
  const Details = state ? state.Details.vendorScreen : [];
  var todayDate = new Date().toISOString().slice(0, 10);
  const FromDate = intialDateState.fromDate;
  const ToDate = intialDateState.toDate;
  const factory = state ? state.factory : [];
  const user = useSelector(selectUser);
  const userID = user ? user.userID : "";
  const companyID = user ? user.companyIDVendor : "";
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["user"]);
  const [headers, setHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [selectedIndexData, setSeletedIndexData] = useState();
  const [isShown, setIsShown] = useState(false);

  const loading = state ? state.loading : false;
  let total = {
    OrderQty: 0,
    pendingPieces: 0,
    stitchedPieces: 0,
    producedPieces: 0,
    totalPcs: 0,
    okPieces: 0,
    pcsInAlteration: 0,
    rejectedPieces: 0,
  };
  function handleChange(event) {
    //    setSelected(event.target.value);
    let tempDate = state.DateRange;
    tempDate.vendorScreen = event.target.value;
    state.setDateRange(tempDate);
    dispatch(
      setIntialDateState({
        dateRange: event.target.value,
        toDate: state.ToDate.vendorScreen,
        fromDate: state.FromDate.vendorScreen,
      })
    );
    // let _vals = event.target.value ? API_DATA.filter((r) => r.country === event.target.value) : API_DATA;
    // setTableData(_vals);
  }
  const ApiUrl = "https://qualitylite.bluekaktus.com";
  // useEffect(() => {
  //     function Auth() {
  //         if (!user) {
  //             history.push("/");
  //         }
  //     }
  //     function GetDetails() {
  //         setLoading(true);
  //         // console.log(
  //         //     JSON.stringify({
  //         //         basicParams: {
  //         //             companyID: companyID,
  //         //             userID: userID,
  //         //         },
  //         //         reportParams: {
  //         //             fromDate: "",
  //         //             toDate: "",
  //         //             dateRange: DateRange,
  //         //             // locationID: this.state.factoryid,
  //         //             locationLevel: "FACTORY",
  //         //         },
  //         //     })
  //         // );
  //         fetch(
  //          //   `${ApiUrl}/api/bkQuality/reports/getVendorOrderQtyReport_V2`,
  //         // "https://ee68-103-95-82-250.in.ngrok.io/api/bkQuality/reports/getVendorOrderQtyReport_V2",
  //             `${ApiUrl}/api/bkQuality/reports/getVendorOrderQtyReport_V2_Debug`,
  //             {
  //                 method: "POST",
  //                 body: JSON.stringify({
  //                     basicparams: {
  //                         companyID: companyID,
  //                         userID: userID,
  //                     },
  //                     reportParams: {
  //                         fromDate: "",
  //                         toDate: "",
  //                         dateRange: DateRange,
  //                     },
  //                 }),
  //                 headers: {
  //                     "Content-Type": "application/json",
  //                     Accept: "application/json",
  //                 },
  //             }
  //         )
  //             .then((response) => response.json())
  //             .then((responseJson) => {
  //                 // const data = responseJson;
  //                 const data = responseJson.result;
  //                 setDetails(data);
  //                 // console.log(data);
  //                 var fact = [];
  //                 var j;
  //                 for (j of responseJson.result) {
  //                     fact.push({ val: j.companyName, id: j.companyID });
  //                 }
  //                 setfactory(fact);
  //                 setLoading(false);
  //                 // console.log(data.result[0].locationDetails[0]);
  //             });
  //     }
  //     function GetDetailsCustom() {
  //         // console.log(
  //         //     JSON.stringify({
  //         //         basicparams: {
  //         //             companyID: companyID,
  //         //             userID: userID,
  //         //         },
  //         //         reportParams: {
  //         //             fromDate: FromDate,
  //         //             toDate: ToDate,
  //         //             dateRange: "",
  //         //             // locationID: this.state.factoryid,
  //         //             locationLevel: "FACTORY",
  //         //         },
  //         //     })
  //         // );
  //         setLoading(true);
  //         fetch(
  //            // `${ApiUrl}/api/bkQuality/reports/getVendorOrderQtyReport`,
  //           // "https://ee68-103-95-82-250.in.ngrok.io/api/bkQuality/reports/getVendorOrderQtyReport_V2",
  //             `${ApiUrl}/api/bkQuality/reports/getVendorOrderQtyReport_V2_Debug`,
  //             {
  //                 method: "POST",
  //                 body: JSON.stringify({
  //                     basicparams: {
  //                         companyID: companyID,
  //                         userID: userID,
  //                     },
  //                     reportParams: {
  //                         fromDate: FromDate,
  //                         toDate: ToDate,
  //                         dateRange: "",
  //                     },
  //                 }),
  //                 headers: {
  //                     "Content-Type": "application/json",
  //                     Accept: "application/json",
  //                 },
  //             }
  //         )
  //             .then((response) => response.json())
  //             .then((responseJson) => {
  //                 // const data = responseJson;
  //                 const data = responseJson.result;
  //                 setDetails(data);
  //                 // console.log(data);
  //                 var fact = [];
  //                 var j;
  //                 for (j of responseJson.result) {
  //                     fact.push({ val: j.companyName, id: j.companyID });
  //                 }
  //                 setfactory(fact);
  //                 setLoading(false);
  //                 // console.log(data.result[0].locationDetails[0]);
  //             });
  //     }
  //     Auth();
  //     if (DateRange === "custom" && FromDate !== "" && ToDate !== "") {
  //         GetDetailsCustom();
  //     } else {
  //         GetDetails();
  //     }
  // }, [DateRange, FromDate, ToDate]);

  //    const user = useSelector(selectUser);
  //    user?GetDetails():Auth();
  // console.log(`------------`);
  // console.log(Details.result[0]);

  // if(state && state.redirectData.redirectStatus)
  // {
  //     return  <Redirect to={state.redirectData.path} />;
  // }
  // else

  // useEffect(() =>{
  //     if(state)
  //     {
  //         setLoading(state.loading)
  //     }
  // },[state ? state.loading : state])

  useEffect(() => {
    window.onpopstate = () => {
      state.setLoginstatus(-1);
      state.setRedirectData({ redirectStatus: true, state: {}, path: "/" });
    };
  });
  useEffect(() => {
    if (
      document.getElementById("toDate") ||
      document.getElementById("fromDate")
    ) {
      document.getElementById("toDate").value = Moment(
        state.ToDate.vendorScreen
      ).format("YYYY-MM-DD");
      document.getElementById("fromDate").value = Moment(
        state.FromDate.vendorScreen
      ).format("YYYY-MM-DD");
    }
    window.onbeforeunload = function () {
      return false;
    };
    return () => {
      window.onbeforeunload = "";
    };
  }, []);
  useEffect(() => {
    let temp = [
      { label: "Vendor", key: "VENDOR" },
      { label: "Total Lines", key: "TOTAL_LINES" },
      { label: "Active Lines", key: "ACTIVE_LINES" },
      { label: "Order Qty", key: "ORDER_QTY" },
      { label: "Pending", key: "PENDING" },
      { label: "Pcs Stitched", key: "PCS_STITCHED" },
      { label: "Pcs Produced", key: "PCS_PRODUCED" },
      { label: "Pcs Checked", key: "PCS_CHECKED" },
      { label: "Ok Pcs", key: "OK_PCS" },
      { label: "Rectified Pcs", key: "RECTIFIED_PCS" },
      { label: "Pcs in Alter", key: "PCS_IN_ALTER" },
      { label: "Rejected", key: "REJECTED" },
      { label: "DHU%", key: "DHU" },
    ];
    if (state.DateRange.vendorScreen === "today")
      temp.push({ label: "Yesterday DHU%", key: "YESTERDAY_DHU" });
    setHeaders(temp);

    let tempCsv = [];
    for (let i = 0; i < Details.length; i++) {
      tempCsv[i] = {};
      tempCsv[i].VENDOR = Details[i].locationName;
      tempCsv[i].TOTAL_LINES = Details[i].locationDetails[0].totalLines;
      tempCsv[i].ACTIVE_LINES = Details[i].locationDetails[0].activeLines;
      tempCsv[i].ORDER_QTY = Details[i].locationDetails[0].orderQty;
      tempCsv[i].PENDING = Details[i].locationDetails[0].pendingPieces;
      tempCsv[i].PCS_STITCHED = Details[i].locationDetails[0].stitchedPieces;
      tempCsv[i].PCS_PRODUCED = Details[i].locationDetails[0].producedPieces;
      tempCsv[i].PCS_CHECKED =
        parseInt(Details[i].locationDetails[0].okPieces) +
        parseInt(Details[i].locationDetails[0].alteredPieces) +
        parseInt(Details[i].locationDetails[0].pcsInAlteration) +
        parseInt(Details[i].locationDetails[0].rejectedPieces);
      tempCsv[i].OK_PCS = Details[i].locationDetails[0].okPieces;
      tempCsv[i].RECTIFIED_PCS = Details[i].locationDetails[0].alteredPieces;
      tempCsv[i].PCS_IN_ALTER = Details[i].locationDetails[0].pcsInAlteration;
      tempCsv[i].REJECTED = Details[i].locationDetails[0].rejectedPieces;
      tempCsv[i].DHU = Details[i].locationDetails[0].dhu;
      if (state.DateRange.vendorScreen === "today")
        tempCsv[i].YESTERDAY_DHU = Details[i].locationDetails[0].y_dhu;
    }
    setCsvData(tempCsv);
  }, [Details, state.DateRange.vendorScreen]);
  function headerSyncFun() {
    state.getDetails();
  }

  console.log(isShown)
  return (
    <>
      {state && (
        <div className={classes.root}>
          <Header
            title="REPORT VENDOR"
            redirectFunc={state.setRedirectData}
            setLoginstatus={state.setLoginstatus}
            headerSyncFun={headerSyncFun}
            syncAllowed = {true}
            handleRemoveCookie={state.handleRemoveCookie}
          />
          <Grid container component="main" className={classes.gridroot}>
            <BeatLoader
              color={"#281d4d"}
              loading={loading}
              size={30}
              css={`
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            />

            <CssBaseline />
            {/* <img src={"../../public/loader1.gif"} alt="loading..." /> */}
            <div className={classes.header}>
              {/* <label value="Select Time: ">Select Time: </label> */}
              <Grid container justify="space-around">
                {/* <Icon path={mdiSyncCircle}
                        title="Sync"
                        size={1.5}
                        horizontal
                        vertical
                        rotate={90}
                        style={{alignSelf: 'center'}}
                        onClick={() =>{state.getDetails();}}
                        color="blue"
                    /> */}
                <Select
                  style={{ width: "30%" }}
                  value={selected}
                  onChange={handleChange}
                  name="country"
                  displayEmpty
                  autoWidth
                  className={classes.selectEmpty}
                >
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="yesterday">Yesterday</MenuItem>
                  <MenuItem value="lastSevenDays">Last 7 Days</MenuItem>
                  <MenuItem value="lastThirtyDays">Last 30 Days</MenuItem>
                  <MenuItem value="custom">Custom</MenuItem>
                </Select>
                <CSVLink
                  data={csvData}
                  headers={headers}
                  filename={
                    "VendorLevel-" +
                    Moment(new Date()).format("DD-MM-YYYY") +
                    ".csv"
                  }
                  className="btn btn-primary"
                  variant="contained"
                  component="label"
                  target="_blank"
                  style={{
                    backgroundColor: "#3f51b5",
                    padding: 10,
                    borderRadius: 5,
                    color: "white",
                    textDecoration: "none",
                    alignSelf: "center",
                  }}
                >
                  EXPORT TO EXCEL
                </CSVLink>
                {state && state.DateRange.vendorScreen === "custom" ? (
                  <TextField
                    id="fromDate"
                    label="Select Start Date"
                    disabled={
                      state.DateRange.vendorScreen === "custom" ? false : true
                    }
                    type="date"
                    defaultValue={state.FromDate.vendorScreen.slice(0, 10)}
                    onChange={(e) => {
                      var monthNames = [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                      ];
                      var res = e.target.value.split("-");
                      // console.log(res);
                      var ans =
                        res[2] +
                        "-" +
                        monthNames[parseInt(res[1]) - 1] +
                        "-" +
                        res[0];
                      // console.log(ans);
                      dispatch(
                        setIntialDateState({
                          dateRange: state
                            ? state.DateRange.vendorScreen
                            : "today",
                          toDate: state.ToDate.vendorScreen,
                          fromDate: ans + " 00:00:00",
                        })
                      );
                      let tempFromDate = state.FromDate;
                      tempFromDate.vendorScreen = ans + " 00:00:00";
                      state.setFromDate(tempFromDate);
                    }}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                ) : (
                  ""
                )}
                {state.DateRange.vendorScreen === "custom" ? (
                  <TextField
                    id="toDate"
                    label="Select End Date"
                    disabled={
                      state.DateRange.vendorScreen === "custom" ? false : true
                    }
                    type="date"
                    onChange={(e) => {
                      var monthNames = [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                      ];
                      var res = e.target.value.split("-");
                      // console.log(res);
                      var ans =
                        res[2] +
                        "-" +
                        monthNames[parseInt(res[1]) - 1] +
                        "-" +
                        res[0];
                      // console.log(ans);
                      dispatch(
                        setIntialDateState({
                          dateRange: state
                            ? state.DateRange.vendorScreen
                            : "today",
                          toDate: ans + " 23:59:59",
                          fromDate: state.FromDate.vendorScreen,
                        })
                      );
                      let tempToDate = state.ToDate;
                      tempToDate.vendorScreen = ans + " 23:59:59";
                      state.setToDate(tempToDate);
                    }}
                    defaultValue={state.ToDate.vendorScreen.slice(0, 10)}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                ) : (
                  ""
                )}
                {!loading ? (
                  <Link
                    onClick={() => {
                      let tempData = state.redirectData;
                      tempData.redirectStatus = true;
                      tempData.path = "";
                      state.setRedirectData({ ...tempData });
                    }}
                    to={{
                      pathname: "/vendorvisualreport",
                      // search: '?query=abc',
                      state: {
                        Data: Details,
                        factory: factory,
                        time: DateRange.vendorScreen,
                        vendorReports: true,
                        vendorID:
                          Details.length != 0 ? Details[0].locationID : "",
                        parentPath: "/reportvendor",
                      },
                    }}
                  >
                    <BarChartIcon style={{ fontSize: 40 }} />
                  </Link>
                ) : null}
                {/* <Link
								to={{
									pathname: '/victoryvisualreport',
									// search: '?query=abc',
									state: { Data: Details, factory: factory , time:DateRange },
								}}
							>
								<BarChartIcon style={{ fontSize: 40 }} />
							</Link> */}
              </Grid>
            </div>
            <Paper className={classes.paper}>
              <TableContainer
                component={Paper}
                style={{
                  margin: "inherit",
                  overflowX: "initial",
                  width: "100vw",
                }}
              >
                {/* <TableContainer className={classes.container}> */}

                <Table aria-label="simple table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeaderFont}>
                        Vendor
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        Total Lines
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        Active Lines
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        Order Qty
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        Pending
                      </TableCell>
                      {/* <TableCell align="right" className={classes.tableHeaderFont}>
                                            Pcs Stitched{"\n"}(cumulative)
                                        </TableCell> */}
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        Pcs Stitched{"\n"}(cumulative)
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        Pcs Checked{"\n"}(
                        {selected === "today" ? "Today" : null}
                        {selected === "yesterday" ? "Yesterday" : null}
                        {selected === "lastSevenDays" ? "Last 7 Days" : null}
                        {selected === "lastThirtyDays" ? "Last 30 Days" : null}
                        {selected === "custom" ? "Custom" : null})
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        OK Pcs{"\n"}({selected === "today" ? "Today" : null}
                        {selected === "yesterday" ? "Yesterday" : null}
                        {selected === "lastSevenDays" ? "Last 7 Days" : null}
                        {selected === "lastThirtyDays" ? "Last 30 Days" : null}
                        {selected === "custom" ? "Custom" : null})
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        Rectified Pcs{"\n"}(
                        {selected === "today" ? "Today" : null}
                        {selected === "yesterday" ? "Yesterday" : null}
                        {selected === "lastSevenDays" ? "Last 7 Days" : null}
                        {selected === "lastThirtyDays" ? "Last 30 Days" : null}
                        {selected === "custom" ? "Custom" : null})
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        Pcs in Alter{"\n"}(
                        {selected === "today" ? "Today" : null}
                        {selected === "yesterday" ? "Yesterday" : null}
                        {selected === "lastSevenDays" ? "Last 7 Days" : null}
                        {selected === "lastThirtyDays" ? "Last 30 Days" : null}
                        {selected === "custom" ? "Custom" : null})
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        Rejected{"\n"}({selected === "today" ? "Today" : null}
                        {selected === "yesterday" ? "Yesterday" : null}
                        {selected === "lastSevenDays" ? "Last 7 Days" : null}
                        {selected === "lastThirtyDays" ? "Last 30 Days" : null}
                        {selected === "custom" ? "Custom" : null})
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        DHU%
                      </TableCell>
                      {state.DateRange.vendorScreen === "today" ? (
                        <TableCell
                          align="right"
                          className={classes.tableHeaderFont}
                        >
                          Yesterday DHU%
                        </TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {Details.length
                                        ? Details.map((Details, i) => {
                                            total.OrderQty = parseInt(total.OrderQty) + parseInt(Details.locationDetails[0].orderQty);
                                            total.pendingPieces = parseInt(total.pendingPieces) + parseInt(Details.locationDetails[0].pendingPieces);
                                            total.stitchedPieces = parseInt(total.stitchedPieces) + parseInt(Details.locationDetails[0].stitchedPieces);
                                            total.producedPieces = parseInt(total.producedPieces) + parseInt(Details.locationDetails[0].producedPieces);
                                            total.totalPcs = parseInt(total.totalPcs) + parseInt(Details.locationDetails[0].okPieces) +
                                            parseInt(Details.locationDetails[0].pcsInAlteration) +
                                            parseInt(Details.locationDetails[0].rejectedPieces);
                                            total.okPieces = parseInt(total.okPieces) + parseInt(Details.locationDetails[0].okPieces);
                                            total.pcsInAlteration = parseInt(total.pcsInAlteration) + parseInt(Details.locationDetails[0].pcsInAlteration);
                                            total.rejectedPieces = parseInt(total.rejectedPieces) + parseInt(Details.locationDetails[0].rejectedPieces);
                                            }):""} */}
                    {Details.length
                      ? Details.map((Details, i) => (
                          <TableRow>
                            <TableCell style={{ padding: 5 }}>
                              {Details.locationID != 0 ? (
                                <Link
                                  onClick={() => {
                                    dispatch(setCompany(Details.locationID));
                                    let temp = state.redirectData;
                                    temp.redirectStatus = true;
                                    temp.state.vendorScreen = {
                                      companyID: Details.locationID,
                                      time: state
                                        ? state.DateRange.vendorScreen
                                        : "today",
                                      path: [Details.locationName],
                                      parentPath: "/reportvendor",
                                    };
                                    temp.path = "/report";
                                    state.setRedirectData({ ...temp });
                                    let tempDateRange = state.DateRange;
                                    tempDateRange.reportScreen =
                                      state.DateRange.vendorScreen;
                                    state.setDateRange({ ...tempDateRange });
                                    let tempFromDate = state.FromDate;
                                    let tempToDate = state.ToDate;
                                    tempFromDate.reportScreen =
                                      state.FromDate.vendorScreen;
                                    tempToDate.reportScreen =
                                      state.ToDate.vendorScreen;
                                    state.setFromDate({ ...tempFromDate });
                                    state.setToDate({ ...tempToDate });
                                    // temp = state.ToDate;
                                    // temp.reportScreen = state.ToDate.vendorScreen;
                                    // state.setToDate({...temp});
                                    // temp = state.FromDate;
                                    // temp.reportScreen = state.FromDate.vendorReports;
                                    // state.setFromDate({...temp});
                                    if (
                                      state.DateRange.reportScreen ===
                                        state.DateRange.vendorScreen &&
                                      state.FromDate.reportScreen ===
                                        state.FromDate.vendorScreen &&
                                      state.ToDate.reportScreen ===
                                        state.ToDate.vendorScreen
                                    ) {
                                      state.nextScreenFunc(Details.locationID); //as we are updating DateRange, from and to date states we dont need to call this again.
                                    }
                                    <Redirect to="/report" />;
                                  }}
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                  }}
                                  //   to={{
                                  //       pathname:
                                  //           "/report",
                                  //       // search: '?query=abc',
                                  //       state: {
                                  //           companyID:Details.companyID,
                                  //               time: state ? state.DateRange.vendorScreen : "today",
                                  //               path: Details.companyName
                                  //       },
                                  //   }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: "#3f51b5",
                                      borderRadius: 5,
                                      padding: 10,
                                    }}
                                  >
                                    {Details.locationName}
                                  </div>
                                </Link>
                              ) : (
                                Details.locationName
                              )}
                            </TableCell>
                            <TableCell align="right">
                            
                              <div onClick={()=>{
                                setIsShown(!isShown)
                                setSeletedIndexData(Details)
                             }}>
                                {Details.locationDetails[0].totalLines}
                             
                              </div>
                              
                            </TableCell>
                            <TableCell align="right">
                              {Details.locationDetails[0].activeLines}
                            </TableCell>
                            <TableCell align="right">
                              {Details.locationDetails[0].orderQty}
                            </TableCell>
                            <TableCell align="right">
                              {Details.locationDetails[0].pendingPieces}
                            </TableCell>
                            {/* <TableCell align="right">
                                                      {
                                                          Details
                                                              .locationDetails[0]
                                                              .stitchedPieces
                                                      }
                                                  </TableCell> */}
                            <TableCell align="right">
                              {Details.locationDetails[0].producedPieces}
                            </TableCell>
                            <TableCell align="right">
                              {parseInt(Details.locationDetails[0].okPieces) +
                                parseInt(
                                  Details.locationDetails[0].alteredPieces
                                ) +
                                parseInt(
                                  Details.locationDetails[0].pcsInAlteration
                                ) +
                                parseInt(
                                  Details.locationDetails[0].rejectedPieces
                                )}
                            </TableCell>
                            <TableCell align="right">
                              {Details.locationDetails[0].okPieces}
                            </TableCell>
                            <TableCell align="right">
                              {Details.locationDetails[0].alteredPieces}
                            </TableCell>
                            <TableCell align="right">
                              {Details.locationDetails[0].pcsInAlteration}
                            </TableCell>
                            <TableCell align="right">
                              {Details.locationDetails[0].rejectedPieces}
                            </TableCell>
                            <TableCell align="right">
                              {Details.locationDetails[0].dhu}
                            </TableCell>
                            {state.DateRange.vendorScreen === "today" ? (
                              <TableCell align="right">
                                {Details.locationDetails[0].y_dhu}
                              </TableCell>
                            ) : null}
                          </TableRow>
                        ))
                      : ""}
                    {/* <TableRow>
                                                   <TableCell align="left">
                                                      Total
                                                  </TableCell>
                                                  <TableCell align="right">
                                                      {
                                                          total.OrderQty
                                                      }
                                                  </TableCell>
                                                  <TableCell align="right">
                                                      {
                                                          total.pendingPieces
                                                      }
                                                  </TableCell>
                                                  <TableCell align="right">
                                                      {
                                                         total.stitchedPieces
                                                      }
                                                  </TableCell>
                                                  <TableCell align="right">
                                                      {
                                                          total.producedPieces
                                                      }
                                                  </TableCell>
                                                  <TableCell align="right">
                                                      {
                                                          total.totalPcs
                                                      }
                                                  </TableCell>
                                                  <TableCell align="right">
                                                      {
                                                         total.okPieces
                                                      }
                                                  </TableCell>
                                                  <TableCell align="right">
                                                      {
                                                          total.pcsInAlteration
                                                      }
                                                  </TableCell>
                                                  <TableCell align="right">
                                                      {
                                                          total.rejectedPieces
                                                      }
                                                  </TableCell>
                                                  <TableCell align="right">
                                                        ""
                                                  </TableCell>
                                              </TableRow> */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          
          {isShown?
          <div onClick={()=>{
            setIsShown(!isShown)

          }}
              style={{position:"fixed",zIndex:10,top:0,left:0,display:"flex",flex:1,height:"100%",width:"100%",backdropFilter:"blur(10px)",backgroundColor:"#00000033",justifyContent:"center",alignItems:"center"}}>
           <div style={{width:"60vw",zIndex:12,backgroundColor:"#FFFFFFDD",borderRadius:10,fontWeight:"bold",padding:20,fontsize:22}}>
             {selectedIndexData.locationName}
             {selectedIndexData.locationDetails[0].comments.map((comment,i)=>
              <div style={{fontsize:16,fontWeight:"normal"}}>
                {comment.comment}
              </div>
             )}
            </div>
        </div>:null}
        
        </div>

      )}
      
    </>
  );
};

export default ReportVendor;
