import {createSlice} from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name : "user",
    initialState:{
        user:null,
        intialDateState:{
        dateRange: "today",
        toDate: new Date().toISOString().slice(0, 10),
        fromDate: new Date().toISOString().slice(0, 10),
        },
        allLineDataStatus: false
    },
    reducers:{
     login : (state,action) => {
         state.user=action.payload;
     },
     setCompany : (state, action) =>{
         state.user.companyID=action.payload;
     },
     logout : (state) =>{
         state.user=null;
     },
     setIntialDateState: (state, action) =>{
         state.intialDateState=action.payload;
     },
     setAllLineDataStatus: (state, action) =>{
        state.allLineDataStatus=action.payload;
    }
    },
});

export const {login,logout,setCompany,setIntialDateState, setAllLineDataStatus}=userSlice.actions;
export const selectUser=(state)=>state.user.user;
export const dateState=(state)=>state.user.intialDateState;
export const allLineStatus=(state)=>state.user.allLineDataStatus;
export default userSlice.reducer ;