import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import {
  selectUser,
  allLineStatus,
  setAllLineDataStatus,
} from "../feature/userSlice";
import { useSelector } from "react-redux";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../assets/assets_BKLOGO.png";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Header from "../component/Header";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useDispatch } from "react-redux";
import { login } from "../feature/userSlice";
import BarChartIcon from "@material-ui/icons/BarChart";
import TextField from "@material-ui/core/TextField";
import BeatLoader from "react-spinners/BeatLoader";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { mdiSyncCircle } from "@mdi/js";
import Icon from "@mdi/react";
import Moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";

// import ReportLine from './ReportLine';
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // display:"flex",
  },
  gridroot: {
    // height: '100vh',
    maxWidth: "max-content",
    margin: "auto",
    display: "block",
    marginTop: "30px",
  },
  paper: {
    // margin: theme.spacing(8, 4),
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    width: "100%",
    marginTop: "20px",
  },
  container: {
    height: "100%",
  },
  tableHeaderFont: {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "#3f51b5",
  },
  breadCrumb: { textDecoration: "none", color: "blue" },
}));

const ReportProcess = ({ state }) => {
  const classes = useStyles();
  // console.log(res);
  // console.log(ans);
  var todayDate = new Date().toISOString().slice(0, 10);
  const [FromDate, setFromDate] = useState(todayDate);
  const [ToDate, setToDate] = useState(todayDate);
  const Details = state.Details.processScreen
    ? state.Details.processScreen
    : [];
  const [DetailsLocation, setDetailsLocation] = useState([]);
  const user = useSelector(selectUser);
  const userID = user ? user.userID : "";
  const companyID = user ? user.companyID : "";
  const location = useLocation();
  const [DateRange, setDatarange] = useState(state ? state.time : "");
  const selected = state.DateRange.processScreen;
  const factory = state.factory;
  const history = useHistory();
  const [headers, setHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const dispatch = useDispatch();
  const loading = state.loading;
  const checkboxStatus = useSelector(allLineStatus);
  const checkbox = state.checkbox;

  const handleCheckboxChange = (event) => {
    state.setCheckbox(event.target.checked);
    dispatch(setAllLineDataStatus(event.target.checked));
  };

  function handleChange(event) {
    //    setSelected(event.target.value);
    let tempDate = state.DateRange;
    tempDate.processScreen = event.target.value;
    state.setDateRange({ ...tempDate });
    // let _vals = event.target.value ? API_DATA.filter((r) => r.country === event.target.value) : API_DATA;
    // setTableData(_vals);
  }
  const ApiUrl = "https://qualitylite.bluekaktus.com";

  // useEffect(() => {
  //     function Auth() {
  //         if (!user) {
  //             history.push("/");
  //         }
  //     }
  //     function GetDetails() {
  //         // console.log(
  //         //     JSON.stringify({
  //         //         basicparams: {
  //         //             companyID: companyID,
  //         //             userID: userID,
  //         //         },
  //         //         reportParams: {
  //         //             fromDate: "",
  //         //             toDate: "",
  //         //             dateRange: DateRange,
  //         //             // locationID: this.state.factoryid,
  //         //             locationLevel: "FACTORY",
  //         //         },
  //         //     })
  //         // );
  //         setLoading(true);
  //         fetch(
  //             `${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport`,
  //             {
  //                 method: "POST",
  //                 body: JSON.stringify({
  //                     basicparams: {
  //                         companyID: companyID,
  //                         userID: userID,
  //                     },
  //                     reportParams: {
  //                         fromDate: "",
  //                         toDate: "",
  //                         dateRange: DateRange,
  //                         locationID: state.locationID,
  //                         orderID: state.orderID,
  //                         styleID: state.styleID,
  //                         locationLevel: "PROCESS",
  //                     },
  //                 }),
  //                 headers: {
  //                     "Content-Type": "application/json",
  //                     Accept: "application/json",
  //                 },
  //             }
  //         )
  //             .then((response) => response.json())
  //             .then((responseJson) => {
  //                 // const data = responseJson;
  //                 // console.log(data);
  //                 const data = responseJson.result;
  //                 if (data.length > 0) {
  //                     const locationdata = data[0].locationDetails;
  //                     setDetailsLocation(locationdata);
  //                     // console.log(locationdata);
  //                     setDetails(data);
  //                     var fact = [];
  //                     var j;
  //                     for (j of responseJson.result) {
  //                         var i;
  //                         for (i of j.locationDetails) {
  //                             // floor.push({val:j.locationName,id:j.locationID})
  //                             fact.push({
  //                                 val: i.processName,
  //                                 id: i.processID,
  //                             });
  //                         }
  //                     }
  //                     setfactory(fact);
  //                     setLoading(false);
  //                     // console.log(data);
  //                 }

  //                 // console.log(data.result[0].locationDetails[0]);
  //             });
  //     }

  //     function GetAllDetails() {
  //         // console.log(
  //         //     JSON.stringify({
  //         //         basicparams: {
  //         //             companyID: companyID,
  //         //             userID: userID,
  //         //         },
  //         //         reportParams: {
  //         //             fromDate: "",
  //         //             toDate: "",
  //         //             dateRange: DateRange,
  //         //             // locationID: this.state.factoryid,
  //         //             locationLevel: "FACTORY",
  //         //         },
  //         //     })
  //         // );
  //         setLoading(true);
  //         fetch(
  //             `${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport`,
  //             {
  //                 method: "POST",
  //                 body: JSON.stringify({
  //                     basicparams: {
  //                         companyID: companyID,
  //                         userID: userID,
  //                     },
  //                     reportParams: {
  //                         fromDate: "",
  //                         toDate: "",
  //                         dateRange: DateRange,
  //                         orderID: state.orderID,
  //                         styleID: state.styleID,
  //                         locationLevel: "PROCESS",
  //                     },
  //                 }),
  //                 headers: {
  //                     "Content-Type": "application/json",
  //                     Accept: "application/json",
  //                 },
  //             }
  //         )
  //             .then((response) => response.json())
  //             .then((responseJson) => {
  //                 // const data = responseJson;
  //                 // console.log(data);
  //                 const data = responseJson.result;
  //                 if (data.length > 0) {
  //                     const locationdata = data[0].locationDetails;
  //                     setDetailsLocation(locationdata);
  //                     // console.log(locationdata);
  //                     setDetails(data);
  //                     var fact = [];
  //                     var j;
  //                     for (j of responseJson.result) {
  //                         var i;
  //                         for (i of j.locationDetails) {
  //                             // floor.push({val:j.locationName,id:j.locationID})
  //                             fact.push({
  //                                 val: i.processName,
  //                                 id: i.processID,
  //                             });
  //                         }
  //                     }
  //                     setfactory(fact);
  //                     setLoading(false);
  //                     // console.log(data);
  //                 }

  //                 // console.log(data.result[0].locationDetails[0]);
  //             });
  //     }
  //     function GetDetailsCustom() {
  //         // console.log(
  //         //     JSON.stringify({
  //         //         basicparams: {
  //         //             companyID: companyID,
  //         //             userID: userID,
  //         //         },
  //         //         reportParams: {
  //         //             fromDate: FromDate,
  //         //             toDate: ToDate,
  //         //             dateRange: "",
  //         //             // locationID: this.state.factoryid,
  //         //             locationLevel: "FACTORY",
  //         //         },
  //         //     })
  //         // );
  //         setLoading(true);
  //         fetch(
  //             `${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport`,
  //             {
  //                 method: "POST",
  //                 body: JSON.stringify({
  //                     basicparams: {
  //                         companyID: companyID,
  //                         userID: userID,
  //                     },
  //                     reportParams: {
  //                         fromDate: FromDate + " 00:00:00",
  //                         toDate: ToDate + " 23:59:59",
  //                         dateRange: "",
  //                         locationID: state.locationID,
  //                         orderID: state.orderID,
  //                         styleID: state.styleID,
  //                         locationLevel: "PROCESS",
  //                     },
  //                 }),
  //                 headers: {
  //                     "Content-Type": "application/json",
  //                     Accept: "application/json",
  //                 },
  //             }
  //         )
  //             .then((response) => response.json())
  //             .then((responseJson) => {
  //                 // const data = responseJson;
  //                 // console.log(data);
  //                 const data = responseJson.result;
  //                 if (data.length > 0) {
  //                     const locationdata = data[0].locationDetails;
  //                     setDetailsLocation(locationdata);
  //                     // console.log(locationdata);
  //                     setDetails(data);
  //                     setLoading(false);
  //                     // console.log(data);
  //                 }

  //                 // console.log(data.result[0].locationDetails[0]);
  //             });
  //     }
  //     function GetAllDetailsCustom() {
  //         // console.log(
  //         //     JSON.stringify({
  //         //         basicparams: {
  //         //             companyID: companyID,
  //         //             userID: userID,
  //         //         },
  //         //         reportParams: {
  //         //             fromDate: FromDate,
  //         //             toDate: ToDate,
  //         //             dateRange: "",
  //         //             // locationID: this.state.factoryid,
  //         //             locationLevel: "FACTORY",
  //         //         },
  //         //     })
  //         // );
  //         setLoading(true);
  //         fetch(
  //             `${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport`,
  //             {
  //                 method: "POST",
  //                 body: JSON.stringify({
  //                     basicparams: {
  //                         companyID: companyID,
  //                         userID: userID,
  //                     },
  //                     reportParams: {
  //                         fromDate: FromDate + " 00:00:00",
  //                         toDate: ToDate + " 23:59:59",
  //                         dateRange: "",
  //                         orderID: state.orderID,
  //                         styleID: state.styleID,
  //                         locationLevel: "PROCESS",
  //                     },
  //                 }),
  //                 headers: {
  //                     "Content-Type": "application/json",
  //                     Accept: "application/json",
  //                 },
  //             }
  //         )
  //             .then((response) => response.json())
  //             .then((responseJson) => {
  //                 // const data = responseJson;
  //                 // console.log(data);
  //                 const data = responseJson.result;
  //                 if (data.length > 0) {
  //                     const locationdata = data[0].locationDetails;
  //                     setDetailsLocation(locationdata);
  //                     // console.log(locationdata);
  //                     setDetails(data);
  //                     setLoading(false);
  //                     // console.log(data);
  //                 }

  //                 // console.log(data.result[0].locationDetails[0]);
  //             });
  //     }
  // if(state && state.locationID)
  // {
  //     Auth();
  //     if(checkbox)
  //     {
  //         if (DateRange === "custom" && FromDate !== "" && ToDate !== "" ) {
  //             GetAllDetailsCustom();
  //         } else {
  //             GetAllDetails();
  //         }
  //     }
  //     else
  //     {
  //     if (DateRange === "custom" && FromDate !== "" && ToDate !== "" ) {
  //         GetDetailsCustom();
  //     } else {
  //         GetDetails();
  //     }
  //     }
  // }
  // }, [DateRange, FromDate, ToDate, checkbox]);
  //    const user = useSelector(selectUser);
  //    user?GetDetails():Auth();
  // console.log(`------------`);
  // console.log(Details.result[0]);

  useEffect(() => {
    window.onpopstate = () => {
      let temp = state.redirectData;
      temp.path = "/reportline";
      state.setRedirectData({ ...temp });
    };
  });
  function headerSyncFun() {
    state.getDetails(
      state.locationID,
      state.orderID,
      state.styleID,
      state.DateRange.processScreen,
      state.FromDate.processScreen,
      state.ToDate.processScreen
    );
  }

  useEffect(() => {
    if (
      document.getElementById("toDate") ||
      document.getElementById("fromDate")
    ) {
      document.getElementById("toDate").value = Moment(
        state.ToDate.processScreen
      ).format("YYYY-MM-DD");
      document.getElementById("fromDate").value = Moment(
        state.FromDate.processScreen
      ).format("YYYY-MM-DD");
    }
    window.onbeforeunload = function () {
      return false;
    };
    return () => {
      window.onbeforeunload = "";
    };
  }, []);

  useEffect(() => {
    let temp = [
      { label: "Process", key: "PROCESS" },
      { label: "Buyer", key: "BUYER" },
      { label: "Order", key: "ORDER" },
      { label: "Order Qty", key: "ORDER_QTY" },
      { label: "Pending", key: "PENDING" },
      { label: "Pcs Checked", key: "PCS_CHECKED" },
      { label: "Ok Pcs", key: "OK_PCS" },
      { label: "Rectified Pcs", key: "RECTIFIED_PCS" },
      { label: "Pcs in Alter", key: "PCS_IN_ALTER" },
      { label: "Rejected", key: "REJECTED" },
      { label: "DHU%", key: "DHU" },
    ];
    setHeaders(temp);

    let tempCsv = [];
    for (let i = 0; i < Details.length; i++) {
      tempCsv[i] = {};
      tempCsv[i].PROCESS = Details[i].locationDetails[0].processName;
      tempCsv[i].BUYER = Details[i].locationDetails[0].brandName;
      tempCsv[i].ORDER = Details[i].locationDetails[0].orderNo;
      tempCsv[i].ORDER_QTY = Details[i].locationDetails[0].orderQty;
      tempCsv[i].PENDING = Details[i].locationDetails[0].pendingPieces;
      tempCsv[i].PCS_CHECKED =
        parseInt(Details[i].locationDetails[0].okPieces) +
        parseInt(Details[i].locationDetails[0].alteredPieces) +
        parseInt(Details[i].locationDetails[0].pcsInAlteration) +
        parseInt(Details[i].locationDetails[0].rejectedPieces);
      tempCsv[i].OK_PCS = Details[i].locationDetails[0].okPieces;
      tempCsv[i].RECTIFIED_PCS = Details[i].locationDetails[0].alteredPieces;
      tempCsv[i].PCS_IN_ALTER = Details[i].locationDetails[0].pcsInAlteration;
      tempCsv[i].REJECTED = Details[i].locationDetails[0].rejectedPieces;
      tempCsv[i].DHU = Details[i].locationDetails[0].dhu;
    }
    setCsvData(tempCsv);
  }, [Details]);

  return (
    <>
      <div className={classes.root}>
        <Header
          title="REPORT PROCESS"
          redirectFunc={state.setRedirectData}
          setLoginstatus={state.setLoginstatus}
          headerSyncFun={headerSyncFun}
          syncAllowed={true}
        />

        {/* hello : <span> {user ? user.username : ''} </span>
				<br />
				UserId :       useEffect(() => {
        window.onbeforeunload = function() {
           return false;
        };    
        return () => { window.onbeforeunload = ''; };
    }, []);<span> {user ? user.userID : ''} </span> */}
        <BeatLoader
          color={"#281d4d"}
          loading={loading}
          size={30}
          css={`
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        />
        <Grid container component="main" className={classes.gridroot}>
          <CssBaseline />
          <Grid container justify="space-around">
            <div
              onClick={() => {
                let temp = state.redirectData;
                temp.path = state.parentPath;
                state.setRedirectData({ ...temp });
                <Redirect to="/reportline" />;
              }}
            >
              <AiOutlineArrowLeft size="40px" />
            </div>
            <div style={{ alignSelf: "center" }}>
              <span>
                {" "}
                <Link
                  onClick={() => {
                    let temp = state.redirectData;
                    temp.path = "/reportvendor";
                    state.setRedirectData({ ...temp });
                    <Redirect to="/reportvendor" />;
                  }}
                  className={classes.breadCrumb}
                >
                  {" "}
                  {state.path[0]}{" "}
                </Link>{" "}
                /{" "}
                <Link
                  onClick={() => {
                    let temp = state.redirectData;
                    temp.path = "/report";
                    state.setRedirectData({ ...temp });
                    <Redirect to="/report" />;
                  }}
                  className={classes.breadCrumb}
                >
                  {" "}
                  {state.path[1]}{" "}
                </Link>{" "}
                /{" "}
                <Link
                  onClick={() => {
                    let temp = state.redirectData;
                    temp.path = "/reportline";
                    state.setRedirectData({ ...temp });
                    <Redirect to="/reportline" />;
                  }}
                  className={classes.breadCrumb}
                >
                  {" "}
                  {state.path[2]}{" "}
                </Link>
              </span>
            </div>
            {/* <Icon path={mdiSyncCircle}
                        title="Sync"
                        size={1.5}
                        horizontal
                        vertical
                        rotate={90}
                        style={{alignSelf: 'center'}}
                        onClick={() =>{state.getDetails(state.locationID, state.orderID, state.styleID, state.DateRange.processScreen, state.FromDate.processScreen, state.ToDate.processScreen)}}
                        color="blue"
                    /> */}

            {!checkbox && (
              <div style={{ alignSelf: "center" }}>
                <span> {state.lineName} </span>
              </div>
            )}
            <Link
              style={{ alignSelf: "center" }}
              onClick={() => {
                let tempData = state.redirectData;
                tempData.redirectStatus = true;
                tempData.path = "";
                state.setRedirectData({ ...tempData });
              }}
              to={{
                pathname: "/reportstyle",
                // search: '?query=abc',
                state: {
                  locationID: 0,
                  lineID: state.locationID,
                  orderID: state.orderID,
                  styleID: state.styleID,
                  processID: 0,
                  time: state.DateRange.processScreen,
                  parentPath: "/reportprocess",
                },
              }}
            >
              <Button
                variant="contained"
                style={{ alignSelf: "center" }}
                color="primary"
              >
                {" "}
                {state.styleNo}{" "}
              </Button>
              {/* <button style={{alignSelf: 'center'}}> {location.state.styleNo} </button> */}
            </Link>
            <Select
              style={{ width: "30%" }}
              value={selected}
              onChange={handleChange}
              name="country"
              displayEmpty
              autoWidth
              className={classes.selectEmpty}
            >
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="yesterday">Yesterday</MenuItem>
              <MenuItem value="lastSevenDays">Last 7 Days</MenuItem>
              <MenuItem value="lastThirtyDays">Last 30 Days</MenuItem>
              <MenuItem value="custom">Custom</MenuItem>
            </Select>
            <CSVLink
              data={csvData}
              headers={headers}
              filename={
                "ProcessLevel-" +
                Moment(new Date()).format("DD-MM-YYYY") +
                ".csv"
              }
              className="btn btn-primary"
              variant="contained"
              component="label"
              target="_blank"
              style={{
                backgroundColor: "#3f51b5",
                padding: 10,
                borderRadius: 5,
                color: "white",
                textDecoration: "none",
                alignSelf: "center",
              }}
            >
              EXPORT TO EXCEL
            </CSVLink>
            {state.DateRange.processScreen === "custom" ? (
              <TextField
                id="fromDate"
                label="Select Start Date"
                disabled={
                  state.DateRange.processScreen === "custom" ? false : true
                }
                type="date"
                // value={FromDate}
                defaultValue={state.FromDate.processScreen.slice(0, 10)}
                onChange={(e) => {
                  var monthNames = [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ];
                  var res = e.target.value.split("-");
                  // console.log(res);
                  var ans =
                    res[2] +
                    "-" +
                    monthNames[parseInt(res[1]) - 1] +
                    "-" +
                    res[0];
                  // console.log(ans);
                  let tempFromDate = state.FromDate;
                  tempFromDate.processScreen = ans + " 00:00:00";
                  state.setFromDate({ ...tempFromDate });
                }}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : (
              ""
            )}
            {state.DateRange.processScreen === "custom" ? (
              <TextField
                id="toDate"
                label="Select End Date"
                disabled={
                  state.DateRange.processScreen === "custom" ? false : true
                }
                type="date"
                // value={ToDate}
                onChange={(e) => {
                  var monthNames = [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ];
                  var res = e.target.value.split("-");
                  // console.log(res);
                  var ans =
                    res[2] +
                    "-" +
                    monthNames[parseInt(res[1]) - 1] +
                    "-" +
                    res[0];
                  // console.log(ans);
                  let tempToDate = state.ToDate;
                  tempToDate.processScreen = ans + " 23:59:59";
                  state.setToDate({ ...tempToDate });
                }}
                defaultValue={state.ToDate.processScreen.slice(0, 10)}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : (
              ""
            )}
            {!loading ? (
              <Link
                onClick={() => {
                  let tempData = state.redirectData;
                  tempData.redirectStatus = true;
                  tempData.path = "";
                  state.setRedirectData({ ...tempData });
                }}
                to={{
                  pathname: "/visualprocess",
                  // search: '?query=abc',
                  state: {
                    Data: Details,
                    locationID: state.locationID,
                    styleID: state.styleID,
                    orderID: state.orderID,
                    factory: factory,
                    time: state.DateRange.processScreen,
                    allData: checkbox,
                    parentPath: "/reportprocess",
                  },
                }}
              >
                <BarChartIcon style={{ fontSize: 40 }} />
              </Link>
            ) : null}
          </Grid>

          <FormControlLabel
            control={
              <Checkbox
                checked={checkbox}
                color="primary"
                onChange={handleCheckboxChange}
                style={{ marginLeft: 20 }}
              />
            }
            label="View data for all lines"
          />
          <Paper className={classes.paper}>
            <TableContainer
              component={Paper}
              style={{
                margin: "inherit",
                overflowX: "initial",
                width: "100vw",
              }}
            >
              <Table
                className={classes.table}
                aria-label="simple table"
                stickyHeader
              >
                <TableHead>
                  {/* <TableRow> */}
                  <TableRow>
                    <TableCell className={classes.tableHeaderFont}>
                      Process
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont}>
                      Buyer
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont}>
                      Order
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderFont}
                      align="right"
                    >
                      Order Qty
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderFont}
                      align="right"
                    >
                      Pending
                    </TableCell>
                    {/* <TableCell style={{ textAlign: 'left' }}>Pcs Stitched</TableCell>
									<TableCell style={{ textAlign: 'left' }}>Pcs Produced</TableCell> */}
                    <TableCell
                      className={classes.tableHeaderFont}
                      align="right"
                    >
                      Pcs Checked
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderFont}
                      align="right"
                    >
                      OK Pcs
                    </TableCell>
                    <TableCell 
                     className={classes.tableHeaderFont}
                     align="right"
                     > Rectified Pcs</TableCell>
                    <TableCell
                      className={classes.tableHeaderFont}
                      align="right"
                    >
                      Pcs in Alter
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderFont}
                      align="right"
                    >
                      Rejected
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderFont}
                      align="right"
                    >
                      DHU%
                    </TableCell>
                  </TableRow>
                  {/* </TableRow> */}
                </TableHead>
                <TableBody>
                  {Details.map((Details, i) =>
                    Details.locationDetails.map((locationDetails, i) => (
                      <TableRow>
                        {/* <TableRow> */}

                        <TableCell style={{ padding: 5 }}>
                          <Link
                            onClick={() => {
                              let tempData = state.redirectData;
                              tempData.redirectStatus = true;
                              tempData.path = "";
                              state.setRedirectData({ ...tempData });
                            }}
                            to={{
                              pathname: "/reportdefect",
                              // search: '?query=abc',
                              state: {
                                locationID: Details.locationID,
                                lineID: state.locationID,
                                orderID: locationDetails.orderID,
                                styleID: locationDetails.styleID,
                                processID: locationDetails.processID,
                                time: state.DateRange.processScreen,
                                allData: checkbox,
                                parentPath: "/reportprocess",
                              },
                            }}
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#3f51b5",
                                borderRadius: 5,
                                padding: 10,
                              }}
                            >
                              {locationDetails.processName}
                            </div>
                          </Link>
                        </TableCell>
                        <TableCell>{locationDetails.brandName}</TableCell>
                        <TableCell>{locationDetails.orderNo}</TableCell>
                        <TableCell align="right">
                          {locationDetails.orderQty}
                        </TableCell>
                        <TableCell align="right">
                          {locationDetails.pendingPieces}
                        </TableCell>
                        {/* <TableCell style={{ textAlign: 'left' }}>
												{locationDetails.stitchedPieces}
											</TableCell>
											<TableCell style={{ textAlign: 'left' }}>
												{locationDetails.producedPieces}
											</TableCell> */}
                        <TableCell align="right">
                          {parseInt(locationDetails.okPieces) +
                          parseInt( Details.locationDetails[0].alteredPieces) +
                            parseInt(locationDetails.pcsInAlteration) +
                            parseInt(locationDetails.rejectedPieces)}
                        </TableCell>
                        <TableCell align="right">
                          {locationDetails.okPieces}
                        </TableCell>
                        <TableCell align="right">
                        {Details.locationDetails[0].alteredPieces}
                         </TableCell>
                        <TableCell align="right">
                          {locationDetails.pcsInAlteration}
                        </TableCell>
                        <TableCell align="right">
                          {locationDetails.rejectedPieces}
                        </TableCell>
                        <TableCell align="right">
                          {locationDetails.dhu}
                        </TableCell>
                        {/* </TableRow> */}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </div>
    </>
  );
};

export default ReportProcess;
