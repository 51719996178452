import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { selectUser } from "../feature/userSlice";
import { useSelector } from "react-redux";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../assets/assets_BKLOGO.png";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Header from "../component/Header";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useDispatch } from "react-redux";
import { login } from "../feature/userSlice";
import BarChartIcon from "@material-ui/icons/BarChart";
import TextField from "@material-ui/core/TextField";
import BeatLoader from "react-spinners/BeatLoader";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { mdiSyncCircle } from "@mdi/js";
import Icon from "@mdi/react";
import Moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";

// import ReportLine from './ReportLine'
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // display:"flex",
  },
  gridroot: {
    // height: '100vh',
    maxWidth: "max-content",
    margin: "auto",
    display: "block",
    marginTop: "30px",
  },
  paper: {
    // margin: theme.spacing(8, 4),
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    width: "100%",
    marginTop: "20px",
  },
  header: {
    display: "flex",
    alignContent: "space-between",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  container: {
    maxHeight: 440,
  },
  tableHeaderFont: {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "#3f51b5",
  },
  breadCrumb: { textDecoration: "none", color: "blue" },
}));

const Report = ({ state }) => {
  const classes = useStyles();
  const location = useLocation();

  //const [DateRange, setDatarange] = useState("today");
  const [DateRange, setDatarange] = useState(state.companyID ? state.time : "");
  const Details = state.Details.reportScreen;
  //const [selected, setSelected] = React.useState("today");
  const selected = state.DateRange.reportScreen
    ? state.DateRange.reportScreen
    : "";
  var todayDate = new Date().toISOString().slice(0, 10);
  const [FromDate, setFromDate] = useState(todayDate);
  const [ToDate, setToDate] = useState(todayDate);
  const factory = state.factory;
  const user = useSelector(selectUser);
  const userID = user ? user.userID : "";
  const companyID = user ? user.companyID : "";
  const history = useHistory();
  const loading = state.loading;
  const [headers, setHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);

  function handleChange(event) {
    //    setSelected(event.target.value);
    let tempDate = state.DateRange;
    tempDate.reportScreen = event.target.value;
    state.setDateRange({ ...tempDate });
    // let _vals = event.target.value ? API_DATA.filter((r) => r.country === event.target.value) : API_DATA;
    // setTableData(_vals);
  }

  const ApiUrl = "https://qualitylite.bluekaktus.com";

  // useEffect(() => {
  //     function Auth() {
  //         if (!user) {
  //             history.push("/");
  //         }
  //     }
  //     function GetDetails() {
  //         // console.log(
  //         //     JSON.stringify({
  //         //         basicParams: {
  //         //             companyID: companyID,
  //         //             userID: userID,
  //         //         },
  //         //         reportParams: {
  //         //             fromDate: "",
  //         //             toDate: "",
  //         //             dateRange: DateRange,
  //         //             // locationID: this.state.factoryid,
  //         //             locationLevel: "FACTORY",
  //         //         },
  //         //     })
  //         // );
  //         setLoading(true);
  //         fetch(
  //             `${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport`,
  //             {
  //                 method: "POST",
  //                 body: JSON.stringify({
  //                     basicparams: {
  //                         companyID: companyID,
  //                         userID: userID,
  //                     },
  //                     reportParams: {
  //                         fromDate: "",
  //                         toDate: "",
  //                         dateRange: DateRange,
  //                         locationLevel: "FACTORY",
  //                     },
  //                 }),
  //                 headers: {
  //                     "Content-Type": "application/json",
  //                     Accept: "application/json",
  //                 },
  //             }
  //         )
  //             .then((response) => response.json())
  //             .then((responseJson) => {
  //                 // const data = responseJson;
  //                 const data = responseJson.result;
  //                 setDetails(data);
  //                 // console.log(data);
  //                 var fact = [];
  //                 var j;
  //                 for (j of responseJson.result) {
  //                     fact.push({ val: j.locationName, id: j.locationID });
  //                 }
  //                 setfactory(fact);
  //                 setLoading(false);
  //                 // console.log(data.result[0].locationDetails[0]);
  //             });
  //     }
  //     function GetDetailsCustom() {
  //         // console.log(
  //         //     JSON.stringify({
  //         //         basicparams: {
  //         //             companyID: companyID,
  //         //             userID: userID,
  //         //         },
  //         //         reportParams: {
  //         //             fromDate: FromDate,
  //         //             toDate: ToDate,
  //         //             dateRange: "",
  //         //             // locationID: this.state.factoryid,
  //         //             locationLevel: "FACTORY",
  //         //         },
  //         //     })
  //         // );
  //         setLoading(true);
  //         fetch(
  //             `${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport`,
  //             {
  //                 method: "POST",
  //                 body: JSON.stringify({
  //                     basicparams: {
  //                         companyID: companyID,
  //                         userID: userID,
  //                     },
  //                     reportParams: {
  //                         fromDate: FromDate,
  //                         toDate: ToDate,
  //                         dateRange: "",
  //                         locationLevel: "FACTORY",
  //                     },
  //                 }),
  //                 headers: {
  //                     "Content-Type": "application/json",
  //                     Accept: "application/json",
  //                 },
  //             }
  //         )
  //             .then((response) => response.json())
  //             .then((responseJson) => {
  //                 // const data = responseJson;
  //                 const data = responseJson.result;
  //                 setDetails(data);
  //                 // console.log(data);
  //                 var fact = [];
  //                 var j;
  //                 for (j of responseJson.result) {
  //                     fact.push({ val: j.locationName, id: j.locationID });
  //                 }
  //                 setfactory(fact);
  //                 setLoading(false);
  //                 // console.log(data.result[0].locationDetails[0]);
  //             });
  //     }
  //     if(state && state.companyID )
  //     {
  //     Auth();
  //     if (DateRange === "custom" && FromDate !== "" && ToDate !== "") {
  //         GetDetailsCustom();
  //     } else {
  //         GetDetails();
  //     }
  //     }
  // }, [DateRange, FromDate, ToDate]);

  //    const user = useSelector(selectUser);
  //    user?GetDetails():Auth();
  // console.log(`------------`);
  // console.log(Details.result[0]);

  useEffect(() => {
    window.onpopstate = () => {
      let temp = state.redirectData;
      temp.path = "/reportvendor";
      state.setRedirectData({ ...temp });
    };
  });

  useEffect(() => {
    if (
      document.getElementById("toDate") ||
      document.getElementById("fromDate")
    ) {
      document.getElementById("toDate").value = Moment(
        state.ToDate.reportScreen
      ).format("YYYY-MM-DD");
      document.getElementById("fromDate").value = Moment(
        state.FromDate.reportScreen
      ).format("YYYY-MM-DD");
    }
    window.onbeforeunload = function () {
      return false;
    };
    return () => {
      window.onbeforeunload = "";
    };
  }, []);

  useEffect(() => {
    let temp = [
      { label: "Factory", key: "FACTORY" },
      { label: "Order Qty", key: "ORDER_QTY" },
      { label: "Pending", key: "PENDING" },
      { label: "Pcs Stitched", key: "PCS_STITCHED" },
      { label: "Pcs Produced", key: "PCS_PRODUCED" },
      { label: "Pcs Checked", key: "PCS_CHECKED" },
      { label: "Ok Pcs", key: "OK_PCS" },
      { label: "Rectified Pcs", key: "RECTIFIED_PCS" },
      { label: "Pcs in Alter", key: "PCS_IN_ALTER" },
      { label: "Rejected", key: "REJECTED" },
      { label: "DHU%", key: "DHU" },
    ];
    setHeaders(temp);

    let tempCsv = [];
    for (let i = 0; i < Details.length; i++) {
      tempCsv[i] = {};
      tempCsv[i].FACTORY = Details[i].locationName;
      tempCsv[i].ORDER_QTY = Details[i].locationDetails[0].orderQty;
      tempCsv[i].PENDING = Details[i].locationDetails[0].pendingPieces;
      tempCsv[i].PCS_STITCHED = Details[i].locationDetails[0].stitchedPieces;
      tempCsv[i].PCS_PRODUCED = Details[i].locationDetails[0].producedPieces;
      tempCsv[i].PCS_CHECKED =
        parseInt(Details[i].locationDetails[0].okPieces) +
        parseInt(Details[i].locationDetails[0].alteredPieces) +
        parseInt(Details[i].locationDetails[0].pcsInAlteration) +
        parseInt(Details[i].locationDetails[0].rejectedPieces);
      tempCsv[i].OK_PCS = Details[i].locationDetails[0].okPieces;
      tempCsv[i].RECTIFIED_PCS = Details[i].locationDetails[0].alteredPieces;
      tempCsv[i].PCS_IN_ALTER = Details[i].locationDetails[0].pcsInAlteration;
      tempCsv[i].REJECTED = Details[i].locationDetails[0].rejectedPieces;
      tempCsv[i].DHU = Details[i].locationDetails[0].dhu;
    }
    setCsvData(tempCsv);
  }, [Details]);

  function headerSyncFun() {
    state.getDetails(
      companyID,
      state.DateRange.reportScreen,
      state.FromDate.reportScreen,
      state.ToDate.reportScreen
    );
  }

  if (state && state.companyID)
    return (
      <>
        <div className={classes.root}>
          <Header
            title="REPORT FACTORY"
            redirectFunc={state.setRedirectData}
            setLoginstatus={state.setLoginstatus}
            headerSyncFun={headerSyncFun}
            syncAllowed={true}
          />

          {/* hello : <span> {user ? user.username : ''} </span>
				<br />
				UserId : <span> {user ? user.userID : ''} </span> */}

          <Grid container component="main" className={classes.gridroot}>
            <BeatLoader
              color={"#281d4d"}
              loading={loading}
              size={30}
              css={`
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            />
            <CssBaseline />
            <div className={classes.header}>
              <div
                onClick={() => {
                  let temp = state.redirectData;
                  temp.path = state.parentPath;
                  state.setRedirectData({ ...temp });
                  <Redirect to="/reportvendor" />;
                }}
              >
                <AiOutlineArrowLeft size="40px" />
              </div>
              <div style={{ alignSelf: "center" }}>
                <span>
                  {" "}
                  <Link
                    onClick={() => {
                      let temp = state.redirectData;
                      temp.path = "/reportvendor";
                      state.setRedirectData({ ...temp });
                      <Redirect to="/reportvendor" />;
                    }}
                    className={classes.breadCrumb}
                  >
                    {" "}
                    {state.path[0]}{" "}
                  </Link>
                </span>
              </div>
              {/* <Icon path={mdiSyncCircle}
                        title="Sync"
                        size={1.5}
                        horizontal
                        vertical
                        rotate={90}
                        style={{alignSelf: 'center'}}
                        onClick={() =>{state.getDetails(companyID, state.DateRange.reportScreen, state.FromDate.reportScreen, state.ToDate.reportScreen)}}
                        color="blue"
                    /> */}
              {/* <label value="Select Time: ">Select Time: </label> */}
              <Grid container justify="space-around">
                <Select
                  style={{ width: "30%" }}
                  value={selected}
                  onChange={handleChange}
                  name="country"
                  displayEmpty
                  autoWidth
                  className={classes.selectEmpty}
                >
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="yesterday">Yesterday</MenuItem>
                  <MenuItem value="lastSevenDays">Last 7 Days</MenuItem>
                  <MenuItem value="lastThirtyDays">Last 30 Days</MenuItem>
                  <MenuItem value="custom">Custom</MenuItem>
                </Select>
                <CSVLink
                  data={csvData}
                  headers={headers}
                  filename={
                    "FactoryLevel-" +
                    Moment(new Date()).format("DD-MM-YYYY") +
                    ".csv"
                  }
                  className="btn btn-primary"
                  variant="contained"
                  component="label"
                  target="_blank"
                  style={{
                    backgroundColor: "#3f51b5",
                    padding: 10,
                    borderRadius: 5,
                    color: "white",
                    textDecoration: "none",
                    alignSelf: "center",
                  }}
                >
                  EXPORT TO EXCEL
                </CSVLink>
                {state.DateRange.reportScreen === "custom" ? (
                  <TextField
                    id="fromDate"
                    label="Select Start Date"
                    disabled={
                      state.DateRange.reportScreen === "custom" ? false : true
                    }
                    type="date"
                    defaultValue={Moment(state.FromDate.reportScreen).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={(e) => {
                      var monthNames = [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                      ];
                      var res = e.target.value.split("-");
                      // console.log(res);
                      var ans =
                        res[2] +
                        "-" +
                        monthNames[parseInt(res[1]) - 1] +
                        "-" +
                        res[0];
                      // console.log(ans);
                      let tempFromDate = state.FromDate;
                      tempFromDate.reportScreen = ans + " 00:00:00";
                      state.setFromDate({ ...tempFromDate });
                    }}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                ) : (
                  ""
                )}
                {state.DateRange.reportScreen === "custom" ? (
                  <TextField
                    id="toDate"
                    label="Select End Date"
                    disabled={
                      state.DateRange.reportScreen === "custom" ? false : true
                    }
                    type="date"
                    onChange={(e) => {
                      var monthNames = [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                      ];
                      var res = e.target.value.split("-");
                      // console.log(res);
                      var ans =
                        res[2] +
                        "-" +
                        monthNames[parseInt(res[1]) - 1] +
                        "-" +
                        res[0];
                      // console.log(ans);
                      let tempToDate = state.ToDate;
                      tempToDate.reportScreen = ans + " 23:59:59";
                      state.setToDate({ ...tempToDate });
                    }}
                    defaultValue={Moment(state.ToDate.reportScreen).format(
                      "YYYY-MM-DD"
                    )}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                ) : (
                  ""
                )}
                {!loading ? (
                  <Link
                    onClick={() => {
                      let tempData = state.redirectData;
                      tempData.redirectStatus = true;
                      tempData.path = "";
                      state.setRedirectData({ ...tempData });
                    }}
                    to={{
                      pathname: "/visualreport",
                      // search: '?query=abc',
                      state: {
                        Data: Details,
                        factory: factory,
                        time: state.DateRange.reportScreen,
                        toDate: state.ToDate.reportScreen,
                        fromDate: state.FromDate.reportScreen,
                        parentPath: "/report",
                      },
                    }}
                  >
                    <BarChartIcon style={{ fontSize: 40 }} />
                  </Link>
                ) : null}
                {/* <Link
								to={{
									pathname: '/victoryvisualreport',
									// search: '?query=abc',
									state: { Data: Details, factory: factory , time:DateRange },
								}}
							>
								<BarChartIcon style={{ fontSize: 40 }} />
							</Link> */}
              </Grid>
            </div>
            
            <Paper className={classes.paper}>
              <TableContainer
                component={Paper}
                style={{
                  margin: "inherit",
                  overflowX: "initial",
                  width: "100vw",
                }}
              >
                {/* <TableContainer className={classes.container}> */}
                <Table
                  className={classes.table}
                  aria-label="simple table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeaderFont}>
                        Factory
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        Order Qty
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        Pending
                      </TableCell>
                      {/* <TableCell align="right" className={classes.tableHeaderFont}>
                                            Pcs Stitched
                                        </TableCell> */}
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        Pcs Stitched
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        Pcs Checked
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        OK Pcs
                      </TableCell>

                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        Rectified Pcs{"\n"}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        Pcs in Alter
                      </TableCell>

                      

                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        Rejected
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableHeaderFont}
                      >
                        DHU%
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Details.length
                      ? Details.map((Details, i) => (
                          <TableRow>
                            <TableCell style={{ padding: 5 }}>
                              <Link
                                onClick={() => {
                                  let temp = state.redirectData;
                                  temp.redirectStatus = true;
                                  temp.state.reportScreen = {
                                    locationID: Details.locationID,
                                    time: state.DateRange.reportScreen,
                                    sDate: state.FromDate.reportScreen,
                                    eDate: state.ToDate.reportScreen,
                                    path: state.path.concat([
                                      Details.locationName,
                                    ]),
                                    parentPath: "/report",
                                  };
                                  temp.path = "/reportline";
                                  state.setRedirectData({ ...temp });
                                  let tempDateRange = state.DateRange;
                                  tempDateRange.lineScreen =
                                    state.DateRange.reportScreen;
                                  state.setDateRange({ ...tempDateRange });
                                  let tempFromDate = state.FromDate;
                                  let tempToDate = state.ToDate;
                                  tempFromDate.lineScreen =
                                    state.FromDate.reportScreen;
                                  tempToDate.lineScreen =
                                    state.ToDate.reportScreen;
                                  state.setFromDate({ ...tempFromDate });
                                  state.setToDate({ ...tempToDate });
                                  if (
                                    state.DateRange.lineScreen ===
                                      state.DateRange.reportScreen &&
                                    state.FromDate.lineScreen ===
                                      state.FromDate.reportScreen &&
                                    state.ToDate.lineScreen ===
                                      state.ToDate.reportScreen
                                  ) {
                                    state.nextScreenFunc(Details.locationID); //as we are updating DateRange, from and to date states we dont need to call this again.
                                  }
                                  <Redirect to="/reportline" />;
                                }}
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                }}
                                //   to={{
                                //       pathname:
                                //           "/reportline",
                                //       // search: '?query=abc',
                                //       state: {
                                //           locationID:
                                //               Details.locationID,
                                //           time: DateRange,
                                //           sDate: FromDate,
                                //           eDate: ToDate,
                                //           path: state.path + "/" + Details.locationName
                                //       },
                                //   }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#3f51b5",
                                    borderRadius: 5,
                                    padding: 10,
                                  }}
                                >
                                  {Details.locationName}
                                </div>
                              </Link>
                            </TableCell>
                            <TableCell align="right">
                              {Details.locationDetails[0].orderQty}
                            </TableCell>
                            <TableCell align="right">
                              {Details.locationDetails[0].pendingPieces}
                            </TableCell>
                            {/* <TableCell align="right">
                                                      {
                                                          Details
                                                              .locationDetails[0]
                                                              .stitchedPieces
                                                      }
                                                  </TableCell> */}
                            <TableCell align="right">
                              {Details.locationDetails[0].producedPieces}
                            </TableCell>
                            <TableCell align="right">
                              {parseInt(Details.locationDetails[0].okPieces) +
                              parseInt(
                                Details.locationDetails[0].alteredPieces
                              ) +
                                parseInt(
                                  Details.locationDetails[0].pcsInAlteration
                                ) +
                                parseInt(
                                  Details.locationDetails[0].rejectedPieces
                                )}
                            </TableCell>
                            <TableCell align="right">
                              {Details.locationDetails[0].okPieces}
                            </TableCell>
                            <TableCell align="right">
                              {Details.locationDetails[0].alteredPieces}
                            </TableCell>
                            <TableCell align="right">
                              {Details.locationDetails[0].pcsInAlteration}
                            </TableCell>
                            <TableCell align="right">
                              {Details.locationDetails[0].rejectedPieces}
                            </TableCell>
                            <TableCell align="right">
                              {Details.locationDetails[0].dhu}
                            </TableCell>
                          </TableRow>
                        ))
                      : ""}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </div>
      </>
    );
};

export default Report;
