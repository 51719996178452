import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { selectUser } from "../feature/userSlice";
import { useSelector } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Header from "../component/Header";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ReactExcel, readFile, generateObjects } from '@ramonak/react-excel';
// import ReportLine from './ReportLine'
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        // display:"flex",
    },
    gridroot: {
        // height: '100vh',
        maxWidth: "max-content",
        margin: "auto",
        display: "block",
        marginTop: "30px",
    },
    paper: {
        // margin: theme.spacing(8, 4),
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        width: "100%",
        marginTop: "20px",
    },
    header: {
        display: "flex",
        alignContent: "space-between",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    container: {
        maxHeight: 440,
    },
}));

const OrderExcelFile = () => {
    const classes = useStyles();
    const user = useSelector(selectUser);
    const companyID = user ? user.companyID : "";
    const [dialog, setDialog] = useState({visible: false, text: ""});

        const handleUpload = (event) => {
            const file = event.target.files[0];
            //read excel file
            readFile(file)
              .then((readedData) =>  {
            
              let finalOutput = [];
              let foundObject = {};

                (foundObject = readedData.Sheets[readedData.SheetNames[0]]);
              let initialCol = foundObject["!ref"].split(":");
              initialCol = initialCol[0].split(/([0-9]+)/)[0];
              let keys = Object.keys(foundObject);

              let index = -2, //ignore 1st row it is for column header and inc to +1 for 1st index
                styleIndex = -1, // inc to +1 for 1st style index
                sizeIndex = 0,
                processIndex = 0,
                orderDetailIndex = 0;
              let temp = parseInt(initialCol, 36);

              for (let i = 0; i < keys.length; i++) {
                let currentCol = keys[i].split(/([0-9]+)/)[0];

                if (index === -2 && currentCol === initialCol) {
                  index += 1;
                  continue;
                }
                switch (currentCol) {
                  case initialCol: {
                    index += 1;
                    finalOutput[index] = {};
                    finalOutput[index].orderNumber = foundObject[keys[i]].w;
                    finalOutput[index].zedqCompanyId = companyID;
                    styleIndex = -1;
                    sizeIndex = 0;
                    processIndex = 0;
                    orderDetailIndex = 0;
                    finalOutput[index].styles = [];
                    break;
                  }

                  case (!(temp + 1) * 10 + (temp + 1))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0)
                      finalOutput[index].orderDate = foundObject[keys[i]].w;
                    break;
                  }
                  case (!(temp + 2) * 10 + (temp + 2))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0)
                      finalOutput[index].deliveryDate = foundObject[keys[i]].w;
                    break;
                  }
                  case (!(temp + 3) * 10 + (temp + 3))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0)
                      finalOutput[index].brand = foundObject[keys[i]].w;
                    break;
                  }
                  case (!(temp + 4) * 10 + (temp + 4))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0) {
                      (styleIndex = styleIndex + 1);
                        (sizeIndex = 0);
                        (processIndex = 0);
                        (orderDetailIndex = 0);
                      finalOutput[index].styles[styleIndex] = {};
                      finalOutput[index].styles[styleIndex].colours = [];
                      finalOutput[index].styles[styleIndex].sizes = [];
                      finalOutput[index].styles[styleIndex].defects = [];
                      finalOutput[index].styles[styleIndex].processes = [];
                      finalOutput[index].styles[styleIndex].orderDetails = [];

                      finalOutput[index].styles[styleIndex].styleNo =
                        foundObject[keys[i]].w;
                    }
                    break;
                  }
                  case (!(temp + 5) * 10 + (temp + 5))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0)
                      finalOutput[index].styles[styleIndex].styleDesc =
                        foundObject[keys[i]].w;
                    break;
                  }
                  case (!(temp + 6) * 10 + (temp + 6))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0)
                      finalOutput[index].styles[styleIndex].brand =
                        foundObject[keys[i]].w;
                    break;
                  }
                  case (!(temp + 7) * 10 + (temp + 7))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0) {
                      finalOutput[index].styles[styleIndex].colours.push({
                        colour: foundObject[keys[i]].w,
                      });
                    }
                    break;
                  }
                  case (!(temp + 8) * 10 + (temp + 8))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0) {
                      finalOutput[index].styles[styleIndex].sizes[sizeIndex] =
                        {};
                      finalOutput[index].styles[styleIndex].sizes[
                        sizeIndex
                      ].size = foundObject[keys[i]].w;
                    }
                    break;
                  }
                  case (!(temp + 9) * 10 + (temp + 9))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0) {
                      finalOutput[index].styles[styleIndex].sizes[
                        sizeIndex
                      ].sequenceNo = foundObject[keys[i]].w;
                      sizeIndex += 1;
                    }
                    break;
                  }
                  case (!(temp + 10) * 10 + (temp + 10))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0) {
                      finalOutput[index].styles[styleIndex].defects.push({
                        defect: foundObject[keys[i]].w,
                      });
                    }
                    break;
                  }
                  case (!(temp + 11) * 10 + (temp + 11))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0) {
                      finalOutput[index].styles[styleIndex].processes[
                        processIndex
                      ] = {};
                      finalOutput[index].styles[styleIndex].processes[
                        processIndex
                      ].process = foundObject[keys[i]].w;
                    }
                    break;
                  }
                  case (!(temp + 12) * 10 + (temp + 12))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0) {
                      finalOutput[index].styles[styleIndex].processes[
                        processIndex
                      ].isFinalStitching = foundObject[keys[i]].v;
                    }
                    break;
                  }
                  case (!(temp + 13) * 10 + (temp + 13))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0) {
                      finalOutput[index].styles[styleIndex].processes[
                        processIndex
                      ].isFinalFinishing = foundObject[keys[i]].v;
                      processIndex += 1;
                    }
                    break;
                  }
                  case (!(temp + 14) * 10 + (temp + 14))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0) {
                      finalOutput[index].styles[styleIndex].orderDetails[
                        orderDetailIndex
                      ] = {};
                      finalOutput[index].styles[styleIndex].orderDetails[
                        orderDetailIndex
                      ].colour = foundObject[keys[i]].w;
                    }
                    break;
                  }
                  case (!(temp + 15) * 10 + (temp + 15))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0) {
                      finalOutput[index].styles[styleIndex].orderDetails[
                        orderDetailIndex
                      ].size = foundObject[keys[i]].w;
                    }
                    break;
                  }
                  case (!(temp + 16) * 10 + (temp + 16))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0) {
                      finalOutput[index].styles[styleIndex].orderDetails[
                        orderDetailIndex
                      ].qty = foundObject[keys[i]].w;
                    }
                    break;
                  }

                  case (!(temp + 17) * 10 + (temp + 17))
                    .toString(36)
                    .toUpperCase(): {
                    if (index >= 0) {
                      finalOutput[index].styles[styleIndex].orderDetails[
                        orderDetailIndex
                      ].deliveryDate = foundObject[keys[i]].w;
                      orderDetailIndex += 1;
                    }
                    break;
                  }
                }
              }
              console.log("final Op", finalOutput)
              saveAddStyle(finalOutput)})
              .catch((error) => console.error(error));
          };

          function saveAddStyle (sendArray){
              console.log("COMPANY ID IS", companyID)
            fetch("https://qualitylite.bluekaktus.com/api/Integration/uploadOrders", {
              method: "POST",
              body: JSON.stringify({
                orders: sendArray,
              }),
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            })
              .then((response) => response.json())
              .then((responseJson) => {
                let text = "";
                for (let i = 0; i < responseJson.length; i++) {
                  if (responseJson[i].status === false) {
                    text =
                      text +
                      responseJson[i].orderNo +
                      ": " +
                      responseJson[i].message +
                      "\n";
                  }
                }
                if (text != "") setDialog({visible: true, text: text})
                else setDialog({visible: true, text: "File Uploaded Successfully"})
                console.log("response is ", text, responseJson);
              })
              .catch((error) => console.log(error)); //to catch the errors if any
          };


    return (
        <>
            <div className={classes.root}>
                <Header title="Upload Order File" />
                <Grid container component="main" className={classes.gridroot}>
                    <CssBaseline />
    <input
        type='file'
        accept='.xlsx,.xls'
        onChange={handleUpload}
      />
                </Grid>
                <Dialog open={dialog.visible} onEnter={console.log("Hey.")}>
          <DialogTitle>Alert</DialogTitle>
          <DialogContent>{dialog.text}</DialogContent>
          <Button
							color="blue"
							onClick={() => {
                                setDialog({visible: false, text: ""})
							}}
						>
							OK
						</Button>
        </Dialog>
            </div>
        </>
    );
};

export default OrderExcelFile;
