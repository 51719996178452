import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { selectUser } from "../feature/userSlice";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../assets/assets_BKLOGO.png";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Header from "../component/Header";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useDispatch } from "react-redux";
import BarChartIcon from "@material-ui/icons/BarChart";
import TextField from "@material-ui/core/TextField";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DefectStyle from "../component/DefectStyle";
import BeatLoader from "react-spinners/BeatLoader";
import { AiOutlineArrowLeft } from "react-icons/ai";

// import ReportLine from './ReportLine';
const ApiUrl = "https://qualitylite.bluekaktus.com";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        // display: 'flex',
        height: "100vh",
        // backgroundImage: 'url(' + bgimg + ')',
        backgroundRepeat: "no-repeat",
        // backgroundColor: '#281d4d',
        backgroundPosition: "center",
        backgroundSize: "cover",
        // flexDirection: 'column',
    },
    gridroot: {
        // height: '100vh',
        maxWidth: "max-content",
        margin: "auto",
        // display: 'contents',
        justifyContent: "centre",
        alignItem: "centre",
        alignContent: "centre",
        marginTop: "20px",
    },
    paper: {
        // margin: theme.spacing(8, 4),
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        // width: '100%',
        marginTop: "20px",
    },
    header: {
        display: "flex",
        alignContent: "space-between",
        alignItems: "center",
        justifyContent: "space-evenly",
        margin: "inherit",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    // container: {
    // 	maxHeight: 440,
    // },
}));
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#00334F",
        color: theme.palette.common.white,
        fontSize: "large",
    },
    body: {
        fontSize: 50,
    },
}))(TableCell);
const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
});

function Row(props) {
    const { row } = props;
    // console.log(props);
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.Defect}
                </TableCell>
                <TableCell align="right">{row.frequency}</TableCell>
                {/* <TableCell align="right">{row.fat}</TableCell>
				<TableCell align="right">{row.carbs}</TableCell>
				<TableCell align="right">{row.protein}</TableCell> */}
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse
                        style={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                        }}
                        in={open}
                        timeout="auto"
                        unmountOnExit
                    >
                        {/* <Box margin={1}> */}
                        {/* <Typography variant="h6" gutterBottom component="div">
								IMAGE
							</Typography> */}
                        <DefectStyle
                            data={row}
                            frontUrl={props.frontUrl}
                            backUrl={props.backUrl}
                        />
                        {/* </Box> */}
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
const ReportStyle = ({state}) => {
    const classes = useStyles();
    var todayDate = new Date().toISOString().slice(0, 10);
    var res = todayDate.split("-");
    // console.log(res);
    var ans = res[2] + "-" + res[1] + "-" + res[0];
    // console.log(ans);
    const [Details, setDetails] = useState([]);
    const [DetailsLocation, setDetailsLocation] = useState([]);
    const [Defects, setDefects] = useState([]);
    const [FrontUrl, setFrontUrl] = useState("");
    const [BackUrl, setBackUrl] = useState("");
    const [FromDate, setFromDate] = useState(todayDate);
    const [ToDate, setToDate] = useState(todayDate);
    const [open, setOpen] = React.useState(false);
    const classRow = useRowStyles();
    const user = useSelector(selectUser);
    const userID = user ? user.userID : "";
    const companyID = user ? user.companyID : "";
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [DateRange, setDatarange] = useState(location.state.time);
    const [selected, setSelected] = React.useState(location.state.time);
    // console.log(location);
    const history = useHistory();
    function handleChange(event) {
        setSelected(event.target.value);
        setDatarange(event.target.value);
        // let _vals = event.target.value ? API_DATA.filter((r) => r.country === event.target.value) : API_DATA;
        // setTableData(_vals);
    }

    useEffect(() => {
        function Auth() {
            if (!user) {
                history.push("/");
            }
        }
        function GetDetails() {
            // console.log(
            //     JSON.stringify({
            //         basicparams: {
            //             companyID: companyID,
            //             userID: userID,
            //         },
            //         reportParams: {
            //             fromDate: "",
            //             toDate: "",
            //             dateRange: DateRange,
            //             lineID: location.state.lineID,
            //             orderID: location.state.orderID,
            //             styleID: location.state.styleID,
            //             processID: location.state.processID,
            //             // locationLevel: 'PROCESS',
            //         },
            //     })
            // );
            setLoading(true);
            fetch(`${ApiUrl}/api/bkQuality/reports/getStyleDefectsReport`, {
                method: "POST",
                body: JSON.stringify({
                    basicparams: {
                        companyID: companyID,
                        userID: userID,
                    },
                    reportParams: 
                    {
                        fromDate: "",
                        toDate: "",
                        daterange: DateRange,
                        styleID: location.state.styleID,
                        orderID: location.state.orderID,
                      },
                }),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    // const data = responseJson;
                    // console.log(data);
                    const data = responseJson.result;
                    //    console.log(responseJson.result)
                    if (data.length > 0) {
                        const locationdata = data[0];
                        setDetailsLocation(locationdata);

                        setDetails(data);
                        // console.log(Details);
                        // console.log(locationdata);

                        var temp = [];
                        var i;
                        var frontURL = "";
                        var backURL = "";
                        for (i of responseJson.result[0].imageDetails) {
                            if (i.imageType === "FC") {
                                frontURL = i.imageUrl;
                            }
                            if (i.imageType === "BC") {
                                backURL = i.imageUrl;
                            }
                        }
                        setFrontUrl(frontURL);
                        setBackUrl(backURL);
                        if (responseJson.result[0].defectsDetails.length > 0) {
                            for (i of responseJson.result[0].defectsDetails) {
                                var tempx = {
                                    Defect: i.defectsName,
                                    frequency: i.frequency,
                                    FrontDefects: [],
                                    BackDefects: [],
                                };
                                i.coordDetails.forEach((itemx) => {
                                    if (itemx.coord_type === "F") {
                                        var tempxx = {
                                            X: itemx.coord_X,
                                            Y: itemx.coord_Y,
                                        };
                                        tempx.FrontDefects.push(tempxx);
                                    }
                                    if (itemx.coord_type === "B") {
                                        var tempxx = {
                                            X: itemx.coord_X,
                                            Y: itemx.coord_Y,
                                        };
                                        tempx.BackDefects.push(tempxx);
                                    }
                                });
                                temp.push(tempx);
                            }
                        }
                        setDefects(temp);
                    } else {
                        setDetails(data);
                        setDefects(data);
                        setFrontUrl("");
                        setBackUrl("");
                    }
                    setLoading(false);
                    // console.log(data.result[0].locationDetails[0]);
                });
        }
        function GetDetailsCustom() {
            // console.log(
            //     JSON.stringify({
            //         basicparams: {
            //             companyID: companyID,
            //             userID: userID,
            //         },
            //         reportParams: {
            //             fromDate: FromDate + " 00:00:00",
            //             toDate: ToDate + " 23:59:59",
            //             dateRange: "",
            //             lineID: location.state.lineID,
            //             orderID: location.state.orderID,
            //             styleID: location.state.styleID,
            //             processID: location.state.processID,
            //             // locationLevel: 'PROCESS',
            //         },
            //     })
            // );
            setLoading(true);
            fetch(`${ApiUrl}/api/bkQuality/reports/getStyleDefectsReport`, {
                method: "POST",
                body: JSON.stringify({
                    basicparams: {
                        companyID: companyID,
                        userID: userID,
                    },
                    reportParams: 
                    {
                        fromDate: FromDate + " 00:00:00",
                        toDate: ToDate + " 23:59:59",
                        daterange: "",
                        styleID: location.state.styleID,
                        orderID:location.state.orderID
                      },
                }),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    // const data = responseJson;
                    // console.log(data);

                    const data = responseJson.result;
                    // console.log(responseJson.result);
                    if (data.length > 0) {
                        const locationdata = data[0];
                        setDetailsLocation(locationdata);

                        setDetails(data);
                        // console.log(Details);
                        // console.log(locationdata);

                        var temp = [];
                        var i;
                        var frontURL = "";
                        var backURL = "";
                        for (i of responseJson.result[0].imageDetails) {
                            if (i.imageType === "FC") {
                                frontURL = i.imageUrl;
                            }
                            if (i.imageType === "BC") {
                                backURL = i.imageUrl;
                            }
                        }
                        setFrontUrl(frontURL);
                        setBackUrl(backURL);
                        if (responseJson.result[0].defectsDetails.length > 0) {
                            for (i of responseJson.result[0].defectsDetails) {
                                var tempx = {
                                    Defect: i.defectsName,
                                    frequency: i.frequency,
                                    FrontDefects: [],
                                    BackDefects: [],
                                };
                                i.coordDetails.forEach((itemx) => {
                                    if (itemx.coord_type == "F") {
                                        var tempxx = {
                                            X: itemx.coord_X,
                                            Y: itemx.coord_Y,
                                        };
                                        tempx.FrontDefects.push(tempxx);
                                    }
                                    if (itemx.coord_type == "B") {
                                        var tempxx = {
                                            X: itemx.coord_X,
                                            Y: itemx.coord_Y,
                                        };
                                        tempx.BackDefects.push(tempxx);
                                    }
                                });
                                temp.push(tempx);
                            }
                        }
                        setDefects(temp);
                    } else {
                        setDetails(data);
                        setDefects(data);
                        setFrontUrl("");
                        setBackUrl("");
                    }
                    // setDetails(data);
                    // console.log(data);
                    // if(responseJson.result){
                    // setDetails(data);
                    // }else{
                    // 	// const locationdata = data[0];
                    // 	// setDetailsLocation(locationdata);
                    // 	// console.log(locationdata);
                    // 	setDetails(data);
                    // 	console.log(Details);
                    // }

                    // console.log(data.result[0].locationDetails[0]);
                    setLoading(false);
                });
        }
        Auth();
        if (DateRange === "custom" && FromDate !== "" && ToDate !== "") {
            GetDetailsCustom();
        } else {
            GetDetails();
        }
    }, [DateRange, FromDate, ToDate]);
    //    const user = useSelector(selectUser);
    //    user?GetDetails():Auth();
    // console.log(`------------`);
    // console.log(Details.result[0]);
    // console.log(Defects);

    useEffect(() => {
        window.onpopstate = ()=> {
             let temp = state.redirectData; 
             temp.path = "/reportprocess"; 
             state.setRedirectData({...temp}); }
      })
      useEffect(() => {
        window.onbeforeunload = function() {
           return false;
        };    
        return () => { window.onbeforeunload = ''; };
    }, []);
    
    return (
        <>
            <div className={classes.root}>
                <Header title="REPORT DEFECT" redirectFunc={state.setRedirectData} setLoginstatus={state.setLoginstatus}/>

                {/* hello : <span> {user ? user.username : ''} </span>
				<br />
				UserId : <span> {user ? user.userID : ''} </span> */}

                <Grid container component="main" className={classes.gridroot}>
                <BeatLoader color={"#281d4d"} loading={loading} size={30} css={`               
    display: flex;
    justify-content: center;
    align-items: center;
                `}/>
                    {/* <CssBaseline /> */}
                    {/* <div className={classes.header}> */}
                    <Grid container justify="space-around">
                    <div onClick={() => {
                        let tempData = state.redirectData;
                        tempData.redirectStatus = true;
                        tempData.path = location.state.parentPath;
                        state.setRedirectData({...tempData});}}>
                    <AiOutlineArrowLeft size="40px" /> 
                    </div>
                        {/* <div> */}
                        {/* <label value="Select Time: ">Select Time: </label> */}
                        <Select
                            style={{ margin: "0px 19px" }}
                            value={selected}
                            label="Select Time"
                            onChange={handleChange}
                            name="country"
                            displayEmpty
                            className={classes.selectEmpty}
                        >
                            <MenuItem value="today">Today</MenuItem>
                            <MenuItem value="yesterday">Yesterday</MenuItem>
                            <MenuItem value="lastSevenDays">
                                Last 7 Days
                            </MenuItem>
                            <MenuItem value="lastThirtyDays">
                                Last 30 Days
                            </MenuItem>
                            <MenuItem value="custom">Custom</MenuItem>
                        </Select>

                        {DateRange === "custom" ? (
                            <TextField
                                id="date"
                                label="Select Start Date"
                                disabled={DateRange === "custom" ? false : true}
                                type="date"
                                defaultValue={FromDate}
                                onChange={(e) => {
                                    var monthNames = [
                                        "Jan",
                                        "Feb",
                                        "Mar",
                                        "Apr",
                                        "May",
                                        "Jun",
                                        "Jul",
                                        "Aug",
                                        "Sep",
                                        "Oct",
                                        "Nov",
                                        "Dec",
                                    ];
                                    var res = e.target.value.split("-");
                                    // console.log(res);
                                    var ans =
                                        res[2] +
                                        "-" +
                                        monthNames[parseInt(res[1]) - 1] +
                                        "-" +
                                        res[0];
                                    // console.log(ans);
                                    setFromDate(ans);
                                }}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        ) : (
                            ""
                        )}
                        {DateRange === "custom" ? (
                            <TextField
                                id="date"
                                label="Select End Date"
                                disabled={DateRange === "custom" ? false : true}
                                type="date"
                                onChange={(e) => {
                                    var monthNames = [
                                        "Jan",
                                        "Feb",
                                        "Mar",
                                        "Apr",
                                        "May",
                                        "Jun",
                                        "Jul",
                                        "Aug",
                                        "Sep",
                                        "Oct",
                                        "Nov",
                                        "Dec",
                                    ];
                                    var res = e.target.value.split("-");
                                    // console.log(res);
                                    var ans =
                                        res[2] +
                                        "-" +
                                        monthNames[parseInt(res[1]) - 1] +
                                        "-" +
                                        res[0];
                                    // console.log(ans);
                                    setToDate(ans);
                                }}
                                defaultValue={ToDate}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        ) : (
                            ""
                        )}
                    </Grid>
                    {/* <Paper className={classes.pape}> */}
                    <Grid
                        container
                        justify="space-around"
                        component={Paper}
                        elevation={6}
                        style={{
                            margin: "inherit",
                            fontSize: "26px",
                            width: "96vw",
                            color: "white",
                            backgroundColor: "#00334F",
                            padding: "7px 0px",
                        }}
                    >
                        <div>{Details.length ? Details[0].brandName : ""}</div>
                        <div>{Details.length ? Details[0].orderNo : ""}</div>
                        <div>{Details.length ? Details[0].styleNo : ""}</div>
                    </Grid>
                    <Grid
                        container
                        justify="space-around"
                        // component={Paper}
                        elevation={2}
                        style={{
                            margin: "inherit",
                            fontSize: "26px",
                            width: "100vw",
                            color: "black",
                            backgroundColor: "white",
                            padding: "7px 0px",
                        }}
                    >
                        <div style={{ color: "#49b675" }}>
                            OK: {Details.length ? Details[0].okPieces : "0"}
                        </div>
                        <div style={{ color: "#20a2eb" }}>
                            Alter:{" "}
                            {Details.length ? Details[0].alteredPieces : "0"}
                        </div>
                        <div style={{ color: "#ffae42" }}>
                            Def:{" "}
                            {Details.length ? Details[0].defectedPieces : "0"}
                        </div>
                        <div style={{ color: "#e94560" }}>
                            Rej :{" "}
                            {Details.length ? Details[0].rejectedPieces : "0"}
                        </div>
                    </Grid>
                    <span style={{margin: "0px 19px"}}>**Data Across ALL LINES</span>
                    <TableContainer
                        component={Paper}
                        style={{ margin: "inherit", width: "95vw" }}
                    >
                        <Table aria-label="collapsible table">
                            <TableHead style={{ color: "black" }}>
                                <TableRow>
                                    <StyledTableCell />
                                    <StyledTableCell>Defect</StyledTableCell>
                                    <StyledTableCell align="right">
                                        Frequency
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Defects.length !== 0
                                    ? Defects.map((item, i) => (
                                          <Row
                                              row={item}
                                              frontUrl={FrontUrl}
                                              backUrl={BackUrl}
                                          />
                                      ))
                                    : ""}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* </Paper> */}
                </Grid>
            </div>
        </>
    );
};

export default ReportStyle;
