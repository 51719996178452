import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import logoBk from '../assets/assets_BKLOGO.png';
import imLogo from '../image/image.jpg';
import { useHistory, Redirect } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://material-ui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: 'url(' + logoBk + ')',
		backgroundRepeat: 'no-repeat',
		backgroundColor: '#281d4d',
		backgroundSize: 'contain',
		backgroundPosition: 'center',
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export default function SignIn() {
	const classes = useStyles();
	const [companyname, setCompanyname] = useState('');
	const [lastname,setLastname]=useState('');
	const [gstno, setGstno] = useState('');
	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [country, setCountry] = useState('');
	const [zipcode, setZipcode] = useState('');
	const [contactperson, setContactperson] = useState('');
	const [contactemail, setContactemail] = useState('');
	const [contactno, setContactno] = useState('');
	const [password, setPassword] = useState('');
	const [confirmpassword, setConfirmpassword] = useState('');
	const [datasource, setDatasource] = useState([]);
	const [loginStatus, setLoginstatus] = useState(-1);
	const[err,setErr]=useState(-1);
	const[errmsg,setErrmsg]=useState("");
    const history = useHistory();

	const handleClick = () => history.push('/');

	const submit = () => {
		if(companyname===''){
            setErr(0);
			setErrmsg("Enter company Name");
		}
		else if (gstno === '') {
			 setErr(0);
				setErrmsg('Enter GST Number');
		}
		else if (address === '') {
			setErr(0);
			setErrmsg('Enter Address');
		}
		else if (city === '') {
			setErr(0);
			setErrmsg('Enter City');
		}
		else if (state === '') {
			setErr(0);
			setErrmsg('Enter State');
		}
		else if (country === '') {
			setErr(0);
			setErrmsg('Enter Country');
		}
		else if (zipcode === '') {
			setErr(0);
			setErrmsg('Enter Zipcode');
		}
		else if (contactperson === '') {
			setErr(0);
			setErrmsg('Enter Contact Person');
		} 
		else if (contactemail === '') {
			setErr(0);
			setErrmsg('Enter Contact Email');
		}
		else if (contactno === '') {
			setErr(0);
			setErrmsg('Enter Contact Number');
		}
		else if (password === '') {
			setErr(0);
			setErrmsg('Enter Password');
		}
		else if (confirmpassword === '') {
			setErr(0);
			setErrmsg('Re-Type Password');
		}
		else if(password !== confirmpassword){
                console.log("Password did not Match");

				setErr(0);
				setErrmsg("Password did not match!");
				
		}else{
			// if(err!==0){
            //   console.log('Password Match');
			// }
			  setErr(1);
              console.log('Password Match');
			  fetch('/api/bkQuality/users/registerCompany', {
					method: 'POST',
					body: JSON.stringify({
						registrationParams: {
							companyParams: {
								companyName: companyname,
								companyGSTN: gstno,
								companyAddress: address,
								companyCountry: country,
								companyState: state,
								companyCity: city,
								companyZipcode: zipcode,
								contactPerson: contactperson,
								contactPersonEmail: contactemail,
								contactPersonPhone: contactno,
							},
							userParams: {
								userFName: contactperson,
								userLName: lastname,
								loginID: contactemail,
								emailID: contactemail,
								loginPwd: password,
								contactNo: contactno,
								userRoleID: 4,
								userPermissionsParams: {
									editBrand: 1,
									editSize: 1,
									editStyle: 1,
									editColor: 1,
									editDefects: 1,
									editOrders: 1,
									editReports: 1,
									editCompany: 1,
									editFactory: 1,
								},
							},
						},
					}),
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
				})
					.then((response) => response.json())
					.then((responseJson) => {
						console.log(responseJson);
						if(responseJson.result === "Registration Successful"){
							//history.push('/');
							<Redirect to="/" />
						}else{
							console.log("Registration Not Successful")
						}
					})
					.catch((error) => console.log(error));

			
		}
	};

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image} />
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Sign Up
					</Typography>
					<form className={classes.form} noValidate>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="Company Name"
							label="Company Name"
							name="Company Name"
							value={companyname}
							onChange={(e) => setCompanyname(e.target.value)}
							autoComplete="Company Name"
							autoFocus
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="GST Number"
							label="GST Number"
							name="GST Number"
							value={gstno}
							onChange={(e) => setGstno(e.target.value)}
							autoComplete="GST Number"
							autoFocus
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="Address"
							label="Address"
							name="Address"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							autoComplete="Address"
							autoFocus
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="City"
							label="City"
							name="City"
							value={city}
							onChange={(e) => setCity(e.target.value)}
							autoComplete="City"
							autoFocus
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="State"
							label="State"
							name="State"
							value={state}
							onChange={(e) => setState(e.target.value)}
							autoComplete="State"
							autoFocus
						/>

						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="Country"
							label="Country"
							name="Country"
							value={country}
							onChange={(e) => setCountry(e.target.value)}
							autoComplete="Country"
							autoFocus
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="Zipcode"
							label="Zipcode"
							name="Zipcode"
							value={zipcode}
							onChange={(e) => setZipcode(e.target.value)}
							autoComplete="Zipcode"
							autoFocus
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="Contact Person"
							label="Contact Person"
							name="Contact Person"
							value={contactperson}
							onChange={(e) => setContactperson(e.target.value)}
							autoComplete="Contact Person"
							autoFocus
						/>
						<TextField
							variant="outlined"
							margin="normal"
							type="email"
							required
							fullWidth
							id="Contact Email"
							label="Contact Email"
							name="Contact Email"
							value={contactemail}
							onChange={(e) => setContactemail(e.target.value)}
							autoComplete="Contact Email"
							autoFocus
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="Contact Number"
							label="Contact Number"
							name="Contact Number"
							value={contactno}
							onChange={(e) => setContactno(e.target.value)}
							autoComplete="Contact Number"
							autoFocus
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							id="password"
							autoComplete="current-password"
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="Confirm password"
							label="Confirm Password"
							type="password"
							value={confirmpassword}
							onChange={(e) => setConfirmpassword(e.target.value)}
							id="Confirm password"
							autoComplete="current-password"
						/>
						{/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
						<Button
							// type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							onClick={() => {
								submit();
							}}
						>
							Sign Up
						</Button>
						<Grid container>
							{/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
							<Grid item xs>
								<Link to="/" onClick={handleClick} variant="body2">
									{'Have an account? Sign In'}
								</Link>
							</Grid>
						</Grid>
						<Box mt={5}>
							{ err === 0 ? (
								<Typography component="h4" variant="h5" color="error">
									{errmsg}
								</Typography>
								
							) : (
								' '
							)}
						</Box>
					</form>
				</div>
			</Grid>
		</Grid>
	);
}
