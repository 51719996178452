import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { selectUser } from "../feature/userSlice";
import { useSelector } from "react-redux";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../assets/assets_BKLOGO.png";
import BarChartIcon from "@material-ui/icons/BarChart";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Header from "../component/Header";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useDispatch } from "react-redux";
import { login } from "../feature/userSlice";
import TextField from "@material-ui/core/TextField";
import BeatLoader from "react-spinners/BeatLoader";
import { AiOutlineArrowLeft } from "react-icons/ai";

// import ReportLine from './ReportLine';
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        // display:"flex",
    },
    gridroot: {
        // height: '100vh',
        maxWidth: "max-content",
        margin: "auto",
        display: "block",
        marginTop: "30px",
    },
    paper: {
        // margin: theme.spacing(8, 4),
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        width: "100%",
        marginTop: "20px",
    },
    container: {
        maxHeight: 440,
    },
}));

const ReportFloor = () => {
    const classes = useStyles();
    const [Details, setDetails] = useState([]);
    var todayDate = new Date().toISOString().slice(0, 10);
    const [FromDate, setFromDate] = useState(todayDate);
    const [ToDate, setToDate] = useState(todayDate);
    const [factory, setfactory] = useState([]);
    const user = useSelector(selectUser);
    const userID = user ? user.userID : "";
    const companyID = user ? user.companyID : "";
    const location = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [DateRange, setDatarange] = useState(location.state.time);
    const [selected, setSelected] = React.useState(location.state.time);
    function handleChange(event) {
        setSelected(event.target.value);
        setDatarange(event.target.value);
        // let _vals = event.target.value ? API_DATA.filter((r) => r.country === event.target.value) : API_DATA;
        // setTableData(_vals);
    }
    const ApiUrl = "https://qualitylite.bluekaktus.com";

    useEffect(() => {
        function Auth() {
            if (!user) {
                history.push("/");
            }
        }
        function GetDetails() {
            // console.log(
            //     JSON.stringify({
            //         basicparams: {
            //             companyID: companyID,
            //             userID: userID,
            //         },
            //         reportParams: {
            //             fromDate: "",
            //             toDate: "",
            //             dateRange: DateRange,
            //             // locationID: this.state.factoryid,
            //             locationLevel: "FACTORY",
            //         },
            //     })
            // );
            setLoading(true);
            fetch(
                `${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        basicparams: {
                            companyID: companyID,
                            userID: userID,
                        },
                        reportParams: {
                            fromDate: "",
                            toDate: "",
                            dateRange: DateRange,
                            locationID: location.state.locationID,
                            locationLevel: "FLOOR",
                        },
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                }
            )
                .then((response) => response.json())
                .then((responseJson) => {
                    // const data = responseJson;
                    const data = responseJson.result;
                    setDetails(data);
                    // console.log(data);
                    var fact = [];
                    var j;
                    for (j of responseJson.result) {
                        fact.push({ val: j.locationName, id: j.locationID });
                    }
                    setfactory(fact);
                    setLoading(false);
                    // console.log(data.result[0].locationDetails[0]);
                });
        }
        function GetDetailsCustom() {
            // console.log(
            //     JSON.stringify({
            //         basicparams: {
            //             companyID: companyID,
            //             userID: userID,
            //         },
            //         reportParams: {
            //             fromDate: FromDate,
            //             toDate: ToDate,
            //             dateRange: "",
            //             // locationID: this.state.factoryid,
            //             locationLevel: "FACTORY",
            //         },
            //     })
            // );
            setLoading(true);
            fetch(
                `${ApiUrl}/api/bkQuality/reports/getLocationLevelOrderQtyReport`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        basicparams: {
                            companyID: companyID,
                            userID: userID,
                        },
                        reportParams: {
                            fromDate: FromDate,
                            toDate: ToDate,
                            dateRange: "",
                            locationID: location.state.locationID,
                            locationLevel: "FLOOR",
                        },
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                }
            )
                .then((response) => response.json())
                .then((responseJson) => {
                    // const data = responseJson;
                    const data = responseJson.result;
                    setDetails(data);
                    // console.log(data);
                    var fact = [];
                    var j;
                    for (j of responseJson.result) {
                        fact.push({ val: j.locationName, id: j.locationID });
                    }
                    setfactory(fact);
                    setLoading(false);
                    // console.log(data.result[0].locationDetails[0]);
                });
        }
        Auth();
        if (DateRange === "custom" && FromDate !== "" && ToDate !== "") {
            GetDetailsCustom();
        } else {
            GetDetails();
        }
    }, [DateRange, FromDate, ToDate]);

    //    const user = useSelector(selectUser);
    //    user?GetDetails():Auth();
    // console.log(`------------`);
    // console.log(Details.result[0]);
    return (
        <>
            <div className={classes.root}>
                <Header title="REPORT FLOOR" />

                {/* hello : <span> {user ? user.username : ''} </span>
				<br />
				UserId : <span> {user ? user.userID : ''} </span> */}

                <Grid container component="main" className={classes.gridroot}>
                <BeatLoader color={"#281d4d"} loading={loading} size={30} css={`               
    display: flex;
    justify-content: center;
    align-items: center;
                `}/>
                    <CssBaseline />
                    <Grid container justify="space-around">
                    <div onClick={() => <Redirect to="/" />}>
                    <AiOutlineArrowLeft size="40px" /> 
                    </div>
                    <div style={{alignSelf: 'center'}}>
                <span> {location.state.path} </span>
                </div>
                        <Select
                            style={{ width: "30%" }}
                            value={selected}
                            onChange={handleChange}
                            name="country"
                            displayEmpty
                            autoWidth
                            className={classes.selectEmpty}
                        >
                            <MenuItem value="today">Today</MenuItem>
                            <MenuItem value="yesterday">Yesterday</MenuItem>
                            <MenuItem value="lastSevenDays">
                                Last 7 Days
                            </MenuItem>
                            <MenuItem value="lastThirtyDays">
                                Last 30 Days
                            </MenuItem>
                            <MenuItem value="custom">Custom</MenuItem>
                        </Select>
                        {DateRange === "custom" ? (
                            <TextField
                                id="date"
                                label="Select Start Date"
                                disabled={DateRange === "custom" ? false : true}
                                type="date"
                                defaultValue={FromDate}
                                onChange={(e) => {
                                    var monthNames = [
                                        "Jan",
                                        "Feb",
                                        "Mar",
                                        "Apr",
                                        "May",
                                        "Jun",
                                        "Jul",
                                        "Aug",
                                        "Sep",
                                        "Oct",
                                        "Nov",
                                        "Dec",
                                    ];
                                    var res = e.target.value.split("-");
                                    // console.log(res);
                                    var ans =
                                        res[2] +
                                        "-" +
                                        monthNames[parseInt(res[1]) - 1] +
                                        "-" +
                                        res[0];
                                    // console.log(ans);
                                    setFromDate(ans + " 00:00:00");
                                }}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        ) : (
                            ""
                        )}
                        {DateRange === "custom" ? (
                            <TextField
                                id="date"
                                label="Select End Date"
                                disabled={DateRange === "custom" ? false : true}
                                type="date"
                                onChange={(e) => {
                                    var monthNames = [
                                        "Jan",
                                        "Feb",
                                        "Mar",
                                        "Apr",
                                        "May",
                                        "Jun",
                                        "Jul",
                                        "Aug",
                                        "Sep",
                                        "Oct",
                                        "Nov",
                                        "Dec",
                                    ];
                                    var res = e.target.value.split("-");
                                    // console.log(res);
                                    var ans =
                                        res[2] +
                                        "-" +
                                        monthNames[parseInt(res[1]) - 1] +
                                        "-" +
                                        res[0];
                                    // console.log(ans);
                                    setToDate(ans + " 23:59:59");
                                }}
                                defaultValue={ToDate}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        ) : (
                            ""
                        )}
                        {!loading ?
                        <Link
                            to={{
                                pathname: "/visualfloor",
                                // search: '?query=abc',
                                state: {
                                    Data: Details,
                                    locationID: location.state.locationID,
                                    factory: factory,
                                    time: DateRange,
                                },
                            }}
                        >
                            <BarChartIcon style={{ fontSize: 40 }} />
                        </Link> : null
                        }
                    </Grid>
                    <Paper className={classes.paper}>
                        <TableContainer
                            component={Paper}
                            style={{ margin: "inherit", width: "95vw" }}
                        >
                            <Table
                                className={classes.table}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>FLOOR</TableCell>
                                        <TableCell align="right">
                                            Order Qty
                                        </TableCell>
                                        <TableCell align="right">
                                            Pending
                                        </TableCell>
                                        <TableCell align="right">
                                            Pcs Stitched
                                        </TableCell>
                                        <TableCell align="right">
                                            Pcs Produced
                                        </TableCell>
                                        <TableCell align="right">
                                            OK Pcs
                                        </TableCell>
                                        <TableCell align="right">
                                            Pcs in Alter
                                        </TableCell>
                                        <TableCell align="right">
                                            Rejected
                                        </TableCell>
                                        <TableCell align="right">
                                            DHU%
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Details.map((Details, i) => (
                                        <TableRow>
                                            <TableCell
                                                style={{ textAlign: "left" }}
                                            >
                                                <Link
                                                    to={{
                                                        pathname: "/reportline",
                                                        // search: '?query=abc',
                                                        state: {
                                                            locationID:
                                                                Details.locationID,
                                                            time: DateRange,
                                                            sDate: FromDate,
                                                            eDate: ToDate,
                                                            path: location.state.path + "/" + Details.locationName
                                                        },
                                                    }}
                                                >
                                                    {Details.locationName}
                                                </Link>
                                            </TableCell>
                                            <TableCell align="right">
                                                {
                                                    Details.locationDetails[0]
                                                        .orderQty
                                                }
                                            </TableCell>
                                            <TableCell align="right">
                                                {
                                                    Details.locationDetails[0]
                                                        .pendingPieces
                                                }
                                            </TableCell>
                                            <TableCell
                                                style={{ textAlign: "right" }}
                                            >
                                                {
                                                    Details.locationDetails[0]
                                                        .stitchedPieces
                                                }
                                            </TableCell>
                                            <TableCell
                                                style={{ textAlign: "right" }}
                                            >
                                                {
                                                    Details.locationDetails[0]
                                                        .producedPieces
                                                }
                                            </TableCell>
                                            <TableCell
                                                style={{ textAlign: "right" }}
                                            >
                                                {
                                                    Details.locationDetails[0]
                                                        .okPieces
                                                }
                                            </TableCell>
                                            <TableCell
                                                style={{ textAlign: "right" }}
                                            >
                                                {
                                                    Details.locationDetails[0]
                                                        .pcsInAlteration
                                                }
                                            </TableCell>
                                            <TableCell
                                                style={{ textAlign: "right" }}
                                            >
                                                {
                                                    Details.locationDetails[0]
                                                        .rejectedPieces
                                                }
                                            </TableCell>
                                            <TableCell
                                                style={{ textAlign: "right" }}
                                            >
                                                {Details.locationDetails[0].dhu}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </div>
        </>
    );
};

export default ReportFloor;
